import Domain from "../domain";
export default {
    methods: {
        getProfileBacicInfo: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/profile/details",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        buyerEmailVerify: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/profile/email/verify/resend",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        putBankImage: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "portal/profile/image/bank", obj,
                    Domain.APP_BUYER_HEADER_FORM_DATA
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getProfileBacicInfoNamed: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/profile/",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getfollwstoreList: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/profile/follow/store",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        putProfileBacicInfo: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "portal/profile", obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response;
            }
        },
        putProfileImage: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "portal/profile/image", obj,
                    Domain.APP_BUYER_HEADER_FORM_DATA
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        putProfileAddress: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "portal/profile/addresses", obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getUserChatStores: async function() {
            try {
                if (this.$store.state.buyer_accessToken) {
                    let response = await this.axios.get(
                        Domain.APP_DOMAIN + "portal/product/chat/store",
                        Domain.APP_BUYER_HEADER
                    );
                    return response.data;
                }
            } catch (error) {
                throw error;
            }
        },
        getUserChatByStoreID: async function(sid) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/chat/list/" + sid,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getCreditLimit: async function(sid) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/profile/available/credit",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getCodeBasedAuthentication: async function(user_id, code) {
            try {
                let response = await this.axios.get(Domain.APP_DOMAIN + "common/service/sathosa/login/" + user_id + "/" + code);
                return response;
            } catch (error) {
                throw error;
            }
        },
        // save sathosa auth permission with secure token 
        saveSathosaAuthPermission: async function(obj) {
            try {
                let response = await this.axios.post(Domain.APP_DOMAIN + "portal/profile/store/token", obj, Domain.APP_BUYER_HEADER);
                return response;
            } catch (error) {
                throw error;
            }
        },
    }
};