<template>
<div> 
	<div class="module tags-product">
		<h5 class="modtitle">{{title}}</h5>
		<div class="modcontent ">
			<ul class="tags_cloud">
				<li style="width:100%;" v-for="(item, index) in data" :key="index"><a style="width:100%;" @click.prevent.stop="onClickButton(item.value)" href="#" class="" v-bind:class="[item.value === selectedvalue ? 'activeClass' : 'button_grey']">{{item.text}}</a></li>
			</ul>				
		</div>
		
	</div>
</div>  
</template>
<script>
export default {
  name:'deliveryChangeModal',
  props: {
  title: String,
  itemindex: Number,
	data: Array,
	selectedvalue: String,
	triggerShow: false
  },
  components: { 
  },
  data: function () {
    return { 
    }
  },
  watch: {
  }, 
  created(){
  },
  methods: { 
	onClickButton (value) {
		this.$emit('clicked', {value:value, index:this.itemindex})
	}
  }
}
</script>
<style scoped>
.activeClass {
	color: white !important;
    background: #005baa !important;	
	padding: 6px 15px;
    display: inline-block;
    text-transform: capitalize;
    border-radius: 3px;
    border: none;	
}
</style>