<template>
  <div class="checkoutcart">
    <div class="row" style="background-color: rgb(230, 231, 233); margin: 0px;">
      <div class="col-md-8 col-sm-12">
        <h2 class="title" v-if="buynow">Buy Now Cart Summary</h2>
        <h2 class="title" v-else>Checkout Cart Summary</h2>

        <!-- <div class="checkboxes_list text-left ">					  
						
						<input type="checkbox" checked="" @change="selectAllItems('both')" v-model="selectAllcheckBoth" name="category" id="category_1_both">
						<label for="category_1_both">SELECT BOTH</label>				
						
        </div>-->

        <div class="table-responsive form-group" style="margin-top:20px;">
          <table class="table">
            <thead>
              <th colspan="3" class="checkboxes_list text-left">
                <input
                  type="checkbox"
                  checked
                  @change="selectAllItems()"
                  v-model="selectAllcheck"
                  name="category"
                  id="category_1"
                />
                <label for="category_1">Select All</label>
              </th>
            </thead>
            <tbody style>
              <!-- <tr>
                <td>
                  <div
                    class="row hidden-xs"
                    style="margin-top: -16%; position: absolute;  border-bottom: 2px solid #f34423;"
                  >
                    <div class="col-md-4"></div>
                    <div class="col-md-8">
                      <img
                        style="width: 20%; float: left;"
                        src="https://res.cloudinary.com/persystance-network/image/upload/v1593619753/slt/portal_user/1593619753785.png"
                      />
                      <img
                        style="width: 80%; float: left; margin-top: 5%"
                        src="https://res.cloudinary.com/persystance-network/image/upload/v1593619481/slt/portal_user/1593619481528.png"
                      />
                    </div>
                  </div>
                </td>
              </tr> -->
              <tr v-for="(itemp, index) in cart_products" :key="index">
                <td
                  class="text-left col-md-1 checkboxes_list"
                  style="vertical-align: middle; "
                >
                  <input
                    type="checkbox"
                    :id="index + '_pindex'"
                    value="true"
                    @change="calculateTotalfee()"
                    checked
                    v-model="itemp.selected"
                    :disabled="itemp.disable"
                  />
                  <label
                    :for="index + '_pindex'"
                    style="padding-bottom: 12px;"
                    v-bind:style="[itemp.disable ? { display: 'none' } : {}]"
                  ></label>
                </td>
                <td
                  class="text-center col-md-1"
                  style="vertical-align: middle; min-width: 100px; "
                >
                  <a>
                    <img
                      width="70px"
                      :src="itemp.imgpath[0]"
                      alt="Aspire Ultrabook Laptop"
                      title="Aspire Ultrabook Laptop"
                      class="img-thumbnail"
                    />
                  </a>
                </td>
                <td class="text-left col-md-4" style="vertical-align: middle; ">
                  <div class="row">
                    <div
                      class="col-md-12"
                      style="font-size:14px; margin-bottom:5px;"
                    >
                      <a @click="pushToItem(itemp.item_id)">
                        <b>
                          <span
                            class="badge"
                            style="background: rgb(217, 83, 79);"
                            v-if="itemp.flash_dealid"
                            >Flash Deal</span
                          >
                          {{ itemp.product_name }}
                        </b>
                      </a>
                    </div>
                  </div>
                  <div class="row" v-if="itemp.coupon_id && itemp.coupon_amount !== 0">
                    <div style="color: green;" class="col-md-12"><b>Coupon Added</b></div>
                  </div>
                  <div class="row" v-if="itemp.store_name">
                    <div class="col-md-12">Store: {{ itemp.store_name }}</div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      Delivering By :
                      <a>{{ pickDeiveryPrice(itemp.item_id, index) }}</a> &nbsp;
                      <br />
                      <transition name="fade">
                        <CommonOptionsList
                          v-if="showDeliveryPartners[index]"
                          v-bind:title="'Delivery Partners'"
                          v-bind:data="filterDeliveryratesByPId(itemp.item_id)"
                          v-bind:selectedvalue="''"
                          v-bind:itemindex="index"
                        />
                      </transition>
                      <br />
                    </div>
                  </div>

                  <!-- <div class="row" v-for="(item, index) in fetchAttributes(itemp.available_attribute, itemp.changeAttBoj)" :key="index">
												<div class="col-md-12">								
													{{item}}
												</div>
                  </div>-->
                </td>
                <td class="text-left col-md-2">
                  <div class="left-content-product">
                    <div class="content-product-right">
                      <div>
                        <div class="form-group box-info-product">
                          <h6 v-if="!itemp.disable">
                            Only
                            {{
                              itemp.primary_product.quantity -
                                itemp.primary_product.selected_qty
                            }}
                            items left
                          </h6>
                          <h4 style="color:red" v-if="itemp.not_available_zone">
                            NOT AVAILABLE FOR YOUR REGION !
                          </h4>
                          <span v-else>
                            <h4 style="color:red" v-if="itemp.disable">
                              NO STOCK AVAILABLE!
                            </h4>
                          </span>
                          <div
                            class="form-group"
                            style="width:150px;"
                            v-if="!itemp.disable"
                          >
                            <div class="input-group">
                              <div
                                class="input-group-addon"
                                :disabled="
                                  itemp.min_limit <
                                    itemp.primary_product.selected_qty
                                "
                                v-on:click="
                                  quantityDown(
                                    itemp,
                                    itemp.primary_product.selected_qty,
                                    itemp.min_limit
                                  )
                                "
                                style="cursor:pointer; font-weight:800; background-color: white;"
                              >
                                -
                              </div>
                              <input
                                type="text"
                                name="quantity"
                                :disabled="true"
                                v-model="itemp.primary_product.selected_qty"
                                v-on:change="
                                  changeQty(
                                    itemp,
                                    itemp.primary_product.quantity
                                  )
                                "
                                class="form-control"
                                placeholder="Qty"
                                style="text-align:center; font-weight:800; background-color: white;"
                              />
                              <div
                                class="input-group-addon"
                                :disabled="
                                  itemp.max_limit >
                                    itemp.primary_product.selected_qty
                                "
                                v-on:click="
                                  quantityUp(
                                    itemp,
                                    itemp.primary_product.selected_qty,
                                    itemp.max_limit,
                                    itemp.primary_product.quantity
                                  )
                                "
                                style="cursor:pointer; font-weight:800; background-color: white;"
                              >
                                +
                              </div>
                            </div>
                          </div>
                          <span
                            style="color: #01ca01;"
                            v-if="showSpan === itemp.primary_product._id"
                          >
                            Minimum order quantity limit :
                            {{ itemp.min_limit }}
                          </span>
                          <span
                            style="color: #01ca01;"
                            v-if="showSpan1 === itemp.primary_product._id"
                          >
                            Maximum order quantity limit :
                            {{ itemp.max_limit }}
                          </span>
                          <span
                            style="color: #01ca01;"
                            v-if="
                              itemp.primary_product.quantity -
                                itemp.primary_product.selected_qty ===
                                0
                            "
                            >Order reached available stock limit</span
                          >
                          <br v-if="showSpan === itemp.primary_product._id" />
                          <br v-if="showSpan1 === itemp.primary_product._id" />
                          <br
                            v-if="
                              itemp.primary_product.quantity -
                                itemp.primary_product.selected_qty ===
                                0
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-right" style="vertical-align: middle; ">
                  Unit Price : {{ itemp.primary_product.special_price.toFixed(2) }}
                </td>
                <td class="text-right" style="vertical-align: middle; ">
                  Sub Total : {{ itemp.itemTotal.toFixed(2) }}
                </td>
                <td class="text-right" style="vertical-align: middle; ">
                  <button
                    v-if="!buynow"
                    @click="removeCartItems(itemp, index)"
                    type="button"
                    data-toggle="tooltip"
                    title
                    class="btn btn-danger"
                    onclick
                    data-original-title="Remove"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- table e -->
        <div
          class="col-md-12 col-sm-12"
          style="background-color: rgb(230, 231, 233);"
        >
          <hr style="border-top: 1px solid white;" />
          <div id="weightDiv">
            <!-- <p>Total Package Weight: {{ " " + totalWeight + " kg" }}</p> -->
          </div>
        </div>
      </div>

      <div
        class="col-md-4 col-sm-12"
        style="background-color: white; border: 1px solid #ebebeb; -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);"
      >
        <h2 class="title">Order Summary</h2>
        <hr />
        <div class="row" v-if="user_role == 2">
          <div class="col-md-12">
            <table
              class="table table-responsive"
              id="orderInfoTable"
              style="color: black; border: 1px solid #ddd;"
            >
              <tr
                style="background-color: #f4f4f4; line-height: 40px; padding: 10px 15px;"
              >
                <td style="font-size: 14px; padding: 0px 15px;">
                  Order Information
                </td>
              </tr>
              <tr style="line-height: 40px; padding: 10px 15px;">
                <td style="font-size: 14px; padding: 0px 15px;">First Name</td>
              </tr>
              <tr style="line-height: 40px; padding: 10px 15px;">
                <td style="font-size: 14px; padding: 0px 15px;">
                  <input
                    type="text"
                    name="First Name"
                    v-model="guest_form.first_name"
                    placeholder="First Name"
                    class="form-control"
                  />
                </td>
              </tr>
              <tr style="line-height: 40px; padding: 10px 15px;">
                <td style="font-size: 14px; padding: 0px 15px;">Last Name</td>
              </tr>
              <tr style="line-height: 40px; padding: 10px 15px;">
                <td style="font-size: 14px; padding: 0px 15px;">
                  <input
                    type="text"
                    name="First Name"
                    v-model="guest_form.last_name"
                    placeholder="Last Name"
                    class="form-control"
                  />
                </td>
              </tr>
              <tr style="line-height: 40px; padding: 10px 15px;">
                <td style="font-size: 14px; padding: 0px 15px;">Mobile No</td>
              </tr>
              <tr style="padding: 10px 15px;">
                <td style="font-size: 14px; padding: 0px 10px 15px;">
                  <VuePhoneNumberInput
                    ref="inputno1"
                    id="mobileInput1"
                    v-model="guest_form.mobile"
                    @input="validatePhone(guest_form.mobile)"
                    default-country-code="LK"
                    :only-countries="['LK']"
                    :translations="{ phoneNumberLabel: '' }"
                    no-example
                    style="height:30px;"
                  />
                </td>
              </tr>
              <tr style="line-height: 40px; padding: 10px 15px;">
                <td style="font-size: 14px; padding: 0px 15px;">Home No</td>
              </tr>
              <tr style="padding: 10px 15px;">
                <td style="font-size: 14px; padding: 0px 10px 15px;">
                  <VuePhoneNumberInput
                    ref="inputno2"
                    id="mobileInput1"
                    v-model="guest_form.user_home_mobile"
                    @input="validatePhone1(guest_form.user_home_mobile)"
                    default-country-code="LK"
                    :only-countries="['LK']"
                    no-example
                    :translations="{ phoneNumberLabel: '' }"
                    style="height:30px;"
                  />
                </td>
              </tr>
              <tr style="line-height: 40px; padding: 10px 15px;">
                <td style="font-size: 14px; padding: 0px 15px;">Province</td>
              </tr>
              <tr style="line-height: 40px; padding: 10px 15px;">
                <td style="font-size: 14px; padding: 0px 15px;">
                  <select
                    class="form-control"
                    @change="changeProvince($event.target.value, 0, true)"
                    v-model="selectedprovince"
                  >
                    <option value>--- Please Select ---</option>
                    <option
                      v-for="(item, val) in provinceArray[0]"
                      :key="val"
                      :value="item._id"
                      >{{ item.province_name }}</option
                    >
                  </select>
                </td>
              </tr>
              <tr style="line-height: 40px; padding: 10px 15px;">
                <td style="font-size: 14px; padding: 0px 15px;">
                  District / Area
                </td>
              </tr>
              <tr style="line-height: 40px; padding: 10px 15px;">
                <td style="font-size: 14px; padding: 0px 15px;">
                  <select
                    class="form-control"
                    @change="changeRegion($event.target.value, 0, true)"
                    v-model="selectedregion"
                  >
                    <option value>--- Please Select ---</option>
                    <option
                      v-for="(item, val) in regionArray[0]"
                      :key="val"
                      :value="item._id"
                      >{{ item.region_name }}</option
                    >
                  </select>
                </td>
              </tr>
              <tr style="line-height: 40px; padding: 10px 15px;">
                <td style="font-size: 14px; padding: 0px 15px;">City</td>
              </tr>
              <tr style="line-height: 40px; padding: 10px 15px;">
                <td style="font-size: 14px; padding: 0px 15px;">
                  <select
                    class="form-control"
                    @change="changeAgentCity()"
                    v-model="selectedcityAgent"
                  >
                    <option value>--- Please Select ---</option>
                    <option
                      v-for="(item, val) in cityArray[0]"
                      :key="val"
                      :value="item._id"
                      >{{ item.city_name }}</option
                    >
                  </select>
                </td>
              </tr>
              <tr style="line-height: 40px; padding: 10px 15px;">
                <td style="font-size: 14px; padding: 0px 15px;">
                  Address Line 1
                </td>
              </tr>
              <tr style="line-height: 120px; padding: 10px 15px;">
                <td style="font-size: 14px; padding: 0px 15px;">
                  <textarea
                    v-model="guest_form.delivery_address"
                    rows="3"
                    cols="12"
                    type="text"
                    name="Delivery Address"
                    placeholder="Address Line 1"
                    class="form-control"
                    style="height: auto;"
                  ></textarea>
                </td>
              </tr>
              <tr style="line-height: 40px; padding: 10px 15px;">
                <td style="font-size: 14px; padding: 0px 15px;">
                  Address Line 2
                </td>
              </tr>
              <tr style="line-height: 120px; padding: 10px 15px;">
                <td style="font-size: 14px; padding: 0px 10px 15px;">
                  <textarea
                    v-model="guest_form.billing_address"
                    rows="3"
                    cols="12"
                    type="text"
                    name="Billing Address"
                    placeholder="Address Line 2"
                    class="form-control"
                    style="height: auto;"
                  ></textarea>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="row" v-if="user_role == 1">
          <div class="col-sm-12">
            <ul class="yt-accordion">
              <li class="accordion-group" style="display:none;">
                <h3 class="accordion-heading">
                  <i class="fa fa-plus-square"></i>
                  <span>Billing Address</span>
                </h3>
                <div class="accordion-inner">
                  <table class="table table-responsive">
                    <tr style="line-height: 30px;">
                      <td style="font-size: 14px;">{{ billing_fullname }}</td>
                      <td
                        class="text-muted"
                        style="width:25px; font-size:10px;"
                      >
                        <a @click="changeAddress('billing')">Change</a>
                      </td>
                    </tr>
                    <div>
                      <tr style="line-height: 30px;">
                        <td style="font-size: 14px;">
                          {{ billing_address.address_line_1 }},
                          {{ billing_address.address_line_2 }},
                          {{ billing_address.region }},
                          {{ billing_address.city }}.
                        </td>
                        <td
                          class="text-muted"
                          style="width:25px; font-size:10px;"
                        ></td>
                      </tr>
                      <tr style="line-height: 30px;">
                        <td style="font-size: 14px;">
                          {{ billing_address.province }}.
                        </td>
                        <td
                          class="text-muted"
                          style="width:25px; font-size:10px;"
                        ></td>
                      </tr>
                      <tr style="line-height: 30px;">
                        <td style="font-size: 14px;">
                          {{ billing_address.country }}.
                        </td>
                        <td
                          class="text-muted"
                          style="width:25px; font-size:10px;"
                        ></td>
                      </tr>
                      <tr style="line-height: 30px;">
                        <td style="font-size: 14px;">
                          {{ billing_address.postal_code }}.
                        </td>
                        <td
                          class="text-muted"
                          style="width:25px; font-size:10px;"
                        ></td>
                      </tr>
                      <tr style="line-height: 30px;">
                        <td style="font-size: 14px;">
                          {{
                            billing_phone.includes("+")
                              ? billing_phone
                              : "94" + billing_phone
                          }}
                        </td>
                        <td
                          class="text-muted"
                          style="width:25px; font-size:10px;"
                        ></td>
                      </tr>
                    </div>
                  </table>
                </div>
              </li>

              <li class="accordion-group">
                <h3 class="accordion-heading">
                  <i class="fa fa-plus-square"></i>
                  <span>Delivery Address</span>
                </h3>
                <div class="accordion-inner">
                  <table class="table table-responsive">
                    <tr style="line-height: 30px;">
                      <td style="font-size: 14px;">{{ delivery_fullname }}</td>
                      <td
                        class="text-muted"
                        style="width:25px; font-size:10px;"
                      >
                        <a @click="changeAddress('delivery')">Change</a>
                      </td>
                    </tr>
                    <div>
                      <tr style="line-height: 30px;">
                        <td style="font-size: 14px;">
                          {{ delivery_address.address_line_1 }},
                          {{ delivery_address.address_line_2 }},
                          {{ delivery_address.region }},
                          {{ delivery_address.city }}.
                        </td>
                        <td
                          class="text-muted"
                          style="width:25px; font-size:10px;"
                        ></td>
                      </tr>
                      <tr style="line-height: 30px;">
                        <td style="font-size: 14px;">
                          {{ delivery_address.province }}.
                        </td>
                        <td
                          class="text-muted"
                          style="width:25px; font-size:10px;"
                        ></td>
                      </tr>
                      <tr style="line-height: 30px;">
                        <td style="font-size: 14px;">
                          {{ delivery_address.country }}.
                        </td>
                        <td
                          class="text-muted"
                          style="width:25px; font-size:10px;"
                        ></td>
                      </tr>
                      <tr style="line-height: 30px;">
                        <td style="font-size: 14px;">
                          {{ delivery_address.postal_code }}
                        </td>
                        <td
                          class="text-muted"
                          style="width:25px; font-size:10px;"
                        ></td>
                      </tr>
                      <tr style="line-height: 30px;">
                        <td style="font-size: 14px;">
                          {{
                            delivery_phone.includes("+")
                              ? delivery_phone
                              : "+94" + delivery_phone
                          }}
                        </td>
                        <td
                          class="text-muted"
                          style="width:25px; font-size:10px;"
                        ></td>
                      </tr>
                    </div>
                  </table>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="input-group btn-block quantity ">
              <h3 class="title"> <a href="/buyer-vouchers"><i class="fa fa-gift" aria-hidden="true"></i>  Get Vouchers </a></h3>
              <!-- <hr> -->
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <h4 class="title">Voucher</h4>
            <div class="input-group btn-block quantity ">
            <input type="text"
                @input="clearVoucherData()"
                v-model="voucherCode" name="quantity" placeholder="Enter Voucher Code" class="form-control">
            <span class="input-group-btn" style="padding: 0px;">
            <button type="submit" data-toggle="tooltip" title="" class="btn btn-primary" @click="handleVoucherCheck()" :disabled="voucherCode == ''">Apply</button>
            
            </span></div>
            <h4 v-if="this.voucherError != ''" style="color: red">
                {{ this.voucherError + " : " + this.voucherCode }}
            </h4>
            <h4 v-if="this.voucherSuccess != ''" style="color: green">
              {{ this.voucherSuccess + " : " + this.voucherCode }}
            </h4>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <h4 class="title">Payment</h4>
          </div>
        </div>
        <div class="row" style="font-size: 16px; line-height: 30px;">
          <div class="col-xs-8">
            <span class="title">Subtotal</span>
          </div>
          <div class="col-xs-4" style="text-align: right;">
            <span class="title">Rs. {{ subTotal.toFixed(2) }}</span>
          </div>
        </div>
        <div class="row" style="font-size: 16px; line-height: 30px;">
          <div class="col-xs-8">
            <span class="title">Delivery And Handling Fee</span>
          </div>
          <div class="col-xs-4" style="text-align: right;">
            <span class="title">Rs. {{ shippingfee }}</span>
          </div>
        </div>

        <div class="row" style="display: none;">
          <div class="col-md-12">
            <table class="table table-responsive">
              <tr style="line-height: 30px;">
                <td style="font-size: 14px;">Subtotal</td>
                <td class="text-right" style="width:auto; font-size:18px;">
                  Rs. {{ subTotal }}
                </td>
              </tr>
              <tr style="line-height: 30px;">
                <td style="font-size: 14px;">Delivery And Handling Fee</td>
                <td class="text-right" style="width:auto; font-size:18px;">
                  Rs. {{ shippingfee }}
                </td>
              </tr>
              <!-- <tr style="line-height: 30px;">
									<td  style="font-size: 14px;">Delivery Discount</td><td class="text-right" style="width:auto; font-size:18px;">Rs. {{shipping_discount}}</td>
              </tr>-->
            </table>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4 col-xs-4" style="padding-top:15px;">
            <h4>Total</h4>
          </div>
          <div class="col-sm-8 col-xs-8 text-right" style="font-weight:bold;">
            <h2 v-if="(subTotal + shippingfee - voucherValue ) >= 0">Rs. {{ (subTotal + shippingfee - voucherValue ).toFixed(2)}}</h2>
            <h2 v-if="(subTotal + shippingfee - voucherValue ) < 0">Rs. 0.00</h2>
          </div>
        </div>

        <br />

        <div class="row">
          <div class="col-md-12" style="text-align:center;">
            <!-- de1a22 -->
            <button
              type="button"
              @click="handdleCheckOut()"
              style="width:100%;font-weight: 600; margin-bottom: 20px;"
              class="btn proceed-btn"
              :disabled="
                cart_products.length <= 0 ||
                  subTotal == 0 ||
                  this.orderSettings.cod_order_margin > this.subTotal
              "
            >
              Proceed To Checkout
            </button>
            <b
              style="color:red;"
              v-if="this.orderSettings.cod_order_margin > this.subTotal"
            >
              Minimum Cart Value Rs.{{ this.orderSettings.cod_order_margin }}.
              Please Add More Items.
            </b>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="addressModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addressModalCenterTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-md"
        role="document"
        style="padding-top:10%"
      >
        <div class="modal-content">
          <div class="modal-header">
            <button
              v-if="showContent == 2"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="float: right;"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h5
              class="modal-title"
              v-if="showContent == 1"
              id="addressModalLongTitle"
              style="float: left;"
            >
              <span v-if="changeBillingAddress">Billing</span>
              <span v-else>Delivering</span> Address
            </h5>
            <div class="row align-contents" v-if="showContent == 2">
              <h2 class="modal-title" id="addressModalLongTitle">
                Select your payment option
              </h2>
            </div>
            <!-- <div class="row align-contents" v-if="showContent==3">
							<h2 class="modal-title" style="font-size:20px; "  id="addressModalLongTitle">Bank Transfer option</h2>
            </div>-->
            <button
              v-if="showContent == 1"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="float: right;"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row" v-if="showContent == 1">
              <!-- showContent 1= address, 2 =checkout, 3=bank-->
              <div class="col-sm-12">
                <div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12">
                          <b>Personal Details</b>
                          <hr style="margin-bottom: 5px;" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-md-12 required">
                          <label class="control-label" for="input-firstname"
                            >Full Name</label
                          >
                          <input
                            type="text"
                            name="firstname"
                            v-model="temp.fullname"
                            placeholder="Full Name"
                            id="input-firstname"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-md-12 required">
                          <label class="control-label" for="input-firstname"
                            >Phone Number</label
                          >
                          <VuePhoneNumberInput
                            valid-color="green"
                            color="red"
                            v-model="temp.phonenumber"
                            @input="setMobileNo"
                            default-country-code="LK"
                            :only-countries="['LK']"
                            no-example
                            :translations="{ phoneNumberLabel: '' }"
                            ref="inputno"
                            style="height:30px; cursor:auto;"
                            id="mobileInput"
                          />
                          <!-- <input
                            type="text"
                            name="phonenumber"
                            v-model="temp.phonenumber"
                            placeholder="Phone Number"
                            id="input-firstname"
                            class="form-control"
                          />-->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12">
                          <b>Address</b>
                          <hr style="margin-bottom: 5px;" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-md-12 required">
                          <div class>
                            <label class="control-label">Province</label>
                            <select
                              class="form-control"
                              @change="
                                changeProvince($event.target.value, 0, true)
                              "
                              v-model="selectedprovince"
                            >
                              <option value>--- Please Select ---</option>
                              <option
                                v-for="(item, val) in provinceArray[0]"
                                :key="val"
                                :value="item._id"
                                >{{ item.province_name }}</option
                              >
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-md-12 required">
                          <div class>
                            <label class="control-label">District / Area</label>
                            <select
                              class="form-control"
                              @change="
                                changeRegion($event.target.value, 0, true)
                              "
                              v-model="selectedregion"
                            >
                              <option value>--- Please Select ---</option>
                              <option
                                v-for="(item, val) in regionArray[0]"
                                :key="val"
                                :value="item._id"
                                >{{ item.region_name }}</option
                              >
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-md-12 required">
                          <div class="form-group required">
                            <label class="control-label">City</label>
                            <select class="form-control" v-model="selectedcity">
                              <option value>--- Please Select ---</option>
                              <option
                                v-for="(item, val) in cityArray[0]"
                                :key="val"
                                :value="item._id"
                                >{{ item.city_name }}</option
                              >
                            </select>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="row">
											<div class=" form-group col-md-12 required">										 
												<div class="form-group required">
													<label class="control-label" for="input-password">Postal Code</label>
													<input type="text" class="form-control" v-model="selectedpostalcode"/>
												</div>							 				 
											</div>
                      </div>-->
                      <div class="row">
                        <div class="form-group col-md-12 required">
                          <div class="form-group required">
                            <label class="control-label" for="input-password"
                              >Address Line 1</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              v-model="selectedAddressLine1"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-md-12 required">
                          <div class="form-group required">
                            <label class="control-label" for="input-password"
                              >Address Line 2</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              v-model="selectedAddressLine2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="showContent == 2">
              <div class="col-md-12 align-contents">
                <!-- <div v-if="user_role != 2"  @click="setAction(1)" class="square-contents align-squares" v-bind:style="activeDiv==1 ? 'background-color:#f9d730;' : '' " >
								<img src="@/assets/image/ic_supiripolacredits@2x.png">
								<div class="squares-text">Supiripola Credit</div>
                </div>-->
                <!-- <div class="square-contents align-squarescard">
								<img src="@/assets/image/ic_credit_card@2x.png">
								<div class="squares-text" 
									style="margin-top: 15px">Card Payment</div>
                </div>-->
                <!-- display: none; -->
                <div
                  style="cursor: pointer;"
                  v-if="user_role != 2"
                  @click="setAction(4)"
                  class="square-contents align-squares"
                  v-bind:style="
                    activeDiv == 4 ? 'background-color:#5779ae;' : 'background-color: #a5a5a5;'
                  "
                >
                  <img src="@/assets/image/ic_credit_card_white@2x.png" />
                  <div class="squares-text" style="text-align: -webkit-center;">
                    Card Payment
                  </div>
                </div>
                <div
                  style="cursor: pointer;"
                  @click="setAction(2)"
                  class="square-contents align-squares"
                  v-bind:style="
                    activeDiv == 2 ? 'background-color: #5779ae;' : 'background-color: #a5a5a5;'
                  "
                >
                  <img src="@/assets/image/ic_cod@2x-white.png" />
                  <div class="squares-text" style="text-align: -webkit-center;">
                    Cash on Delivery
                  </div>
                </div>
                <div
                  style="cursor: pointer;"
                  v-if="user_role != 2"
                  @click="setAction(3)"
                  class="square-contents align-squares"
                  v-bind:style="
                    activeDiv == 3 ? 'background-color: #5779ae;' : 'background-color: #a5a5a5;'
                  "
                >
                  <img src="@/assets/image/ic_bank_tranfer@2x-white.png" />
                  <div class="squares-text" style="text-align: -webkit-center;">
                    Bank Transfer
                  </div>
                </div>
              </div>
              <div
                class="row"
                v-if="showContent == 2"
                style="text-align: center;"
              >
                <br />
                <button
                  type="button"
                  class="btn proceed-btn"
                  @click="proceed"
                  style="font-weight: 600; margin-top:10px;"
                  :disabled="this.activeDiv < 2 || enableProceed"
                >
                  Proceed
                </button>
                <span
                  v-if="enableProceed"
                  style="position: absolute;left: 60%;bottom: 5%;"
                >
                  <div class="sk-chase">
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                  </div>
                </span>
              </div>
            </div>
            <!-- <div class="row  align-contents" v-if="showContent==3">
						<div class="col-md-8">
							
							<div class=" row">
								<div>
									<div class="row" style="text-align: center; color: #ffd300; font-size:20px; font-weight: 600;">{{ subTotal + shippingfee + " "}}LKR</div>
									<div class="row" style="text-align: center; color: Black; font-size:16px;">Amount</div>
								</div>
							</div>
							<div class=" row" style="text-align:center; margin-top:5px; margin-bottom:5px; font-size:14px;">
								
									You can send your payment to us via bank transfer option and 
									make sure you made the payment before checkout
							</div>
							<div class=" row" style="text-align:center; margin-top:5px; margin-bottom:5px; font-size:14px;">
								It is recommended to include Username in Beneficiary Narration.
								Cash Deposit , Credit Advice Slip (on Reference : Username should be mentioned).
								Online transfer , Include Username on Beneficiary Narration.
								CEFT Transfers are recommended for faster clearance
							</div>
							<div class="row">
								<div >
									<div class="form-group " style="width: 100%;">
										<select v-model="bankTranferObj.bank"  @change="setbank($event.target.value)"  class="form-control select-arrow" style="width: 100%;">
											<option value="-1"> Select Bank </option>
											<option  v-for="item in bankOption" :key="item.key" :value="item.key">{{ item.text }}</option>
										</select>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12"  
									style="width: 100%; border-radius: 5px;background-color: #e5e5e5;">
									<div >
										<div class="col-md-12" style="margin-top:5px; margin-bottom:5px;  font-weight: 600; font-size:14px;">
											<div class="col-md-5">Bank</div>
											<div class="col-md-1">:</div>
											<div class="col-md-6">{{ selectedBank.text != undefined ? selectedBank.text : "" }} </div>
										</div>
										<div class="col-md-12" style="margin-top:5px; margin-bottom:5px;  font-weight: 600; font-size:14px;">
											<div class="col-md-5">Branch</div>
											<div class="col-md-1">:</div>
											<div class="col-md-6">{{ selectedBank.branch != undefined ? selectedBank.branch : ""}} </div>
										</div>
										<div class="col-md-12" style="margin-top:5px; margin-bottom:5px;  font-weight: 600;  font-size:14px;">
											<div class="col-md-5">Account Name</div>
											<div class="col-md-1">:</div>
											<div class="col-md-6">{{ selectedBank.account_name != undefined ?  selectedBank.account_name : "  "}} </div>
										</div>
										<div class="col-md-12" style="margin-top:5px; margin-bottom:5px;  font-weight: 600; font-size:14px;">
											<div class="col-md-5">Account No</div>
											<div class="col-md-1">:</div>
											<div class="col-md-6">{{ selectedBank.account_no != undefined ? selectedBank.account_no : "  "}} </div>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div style="text-align: left;margin-top:10px; font-weight: 600; font-size:14px;">
									Attach Slip here
									<button type="button" class="btn btn-secondary" 
										style="color: black; font-weight: 600;"
										@click="pickCoverImage()"
									>Upload</button>
									<input 
										type="file" 
										style="display: none;" 
										ref="coverImage" 
										accept="image/*"
										@change="getImageRes()"
										>
								</div>
							</div>
							<div class="row" v-if="bankSlipImage">
								<img  style="margin-top:10px; width: auto; height:100px;" slot="image" :src="bankSlipImage">
							</div>
							<div style="text-align: center;margin-top:10px; margin-bottom:10px;">
								<button type="button" class="btn proceed-btn" 
									style="color: black; font-weight: 600; font-size: 18px;"
									:disabled="bankSlipImage=='' || bankTranferObj.bank == '-1'"
									@click="submitBankImageSlip"
								>Make Payment</button>
							</div>
						</div>
            </div>-->
          </div>
          <div class="modal-footer" v-if="showContent == 1">
            <div>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                :disabled="formValid"
                @click="NewAddressChange()"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- bank option modal -->
    <div
      class="modal fade"
      id="modalBank"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="row align-contents">
              <h2 class="modal-title">Bank Transfer option</h2>
            </div>
            <button
              style="margin-top: -5%;"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row align-contents">
              <div class="col-md-10">
                <div class="row">
                  <!-- <div class="col-md-4">
									<div class="">Order ID</div>
                  </div>-->
                  <div>
                    <div
                      class="row"
                      style="text-align: center; color: #ffd300; font-size:20px; font-weight: 600;"
                    >
                      {{ (subTotal + shippingfee - voucherValue ).toFixed(2) + " " }}LKR
                    </div>
                    <div
                      class="row"
                      style="text-align: center; color: Black; font-size:16px;"
                    >
                      Amount
                    </div>
                  </div>
                </div>
                <div
                  class="row"
                  style="text-align:center; margin-top:5px; margin-bottom:5px; font-size:12px;"
                >
                  <div class="col-md-12">
                    You can send your payment to us via bank transfer option and
                    make sure you made the payment before checkout
                  </div>
                </div>
                <div
                  class="row"
                  style="text-align:center; margin-top:5px; margin-bottom:5px; font-size:12px;"
                >
                  <div class="col-md-12">
                    It is recommended to include Username in Beneficiary
                    Narration. Cash Deposit , Credit Advice Slip (on Reference :
                    Username should be mentioned). Online transfer , Include
                    Username on Beneficiary Narration. CEFT Transfers are
                    recommended for faster clearance
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group" style="width: 100%;">
                      <select
                        v-model="bankTranferObj.bank"
                        @change="setbank($event.target.value)"
                        class="form-control select-arrow"
                        style="width: 100%;"
                      >
                        <option value="-1">Select Bank</option>
                        <option
                          v-for="item in bankOption"
                          :key="item.key"
                          :value="item.key"
                          >{{ item.text }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-md-12"
                    style="width: 100%; border-radius: 5px;background-color: #e5e5e5;"
                  >
                    <div class="row">
                      <div
                        class="col-md-12"
                        style="margin-top:5px; margin-bottom:5px;  font-weight: 600; font-size:14px;"
                      >
                        <div class="col-md-5 col-xs-3">Bank</div>
                        <div class="col-md-1 col-xs-1">:</div>
                        <div class="col-md-6 col-xs-7">
                          {{
                            selectedBank.text != undefined
                              ? selectedBank.text
                              : ""
                          }}
                        </div>
                      </div>
                      <div
                        class="col-md-12"
                        style="margin-top:5px; margin-bottom:5px;  font-weight: 600; font-size:14px;"
                      >
                        <div class="col-md-5 col-xs-3">Branch</div>
                        <div class="col-md-1 col-xs-1">:</div>
                        <div class="col-md-6 col-xs-7">
                          {{
                            selectedBank.branch != undefined
                              ? selectedBank.branch
                              : ""
                          }}
                        </div>
                      </div>
                      <div
                        class="col-md-12"
                        style="margin-top:5px; margin-bottom:5px;  font-weight: 600;  font-size:14px;"
                      >
                        <div class="col-md-5 col-xs-3">Account Name</div>
                        <div class="col-md-1 col-xs-1">:</div>
                        <div class="col-md-6 col-xs-7">
                          {{
                            selectedBank.account_name != undefined
                              ? selectedBank.account_name
                              : " "
                          }}
                        </div>
                      </div>
                      <div
                        class="col-md-12"
                        style="margin-top:5px; margin-bottom:5px;  font-weight: 600; font-size:14px;"
                      >
                        <div class="col-md-5 col-xs-3">Account No</div>
                        <div class="col-md-1 col-xs-1">:</div>
                        <div class="col-md-6">
                          {{
                            selectedBank.account_no != undefined
                              ? selectedBank.account_no
                              : " "
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div
                      style="text-align: left;margin-top:10px; font-weight: 600; font-size:14px;"
                    >
                      Attach Slip here
                      <button
                        type="button"
                        class="btn proceed-btn"
                        style="font-weight: 600;"
                        @click="pickCoverImage()"
                      >
                        Upload
                      </button>
                      <!-- <input type="file" class="inputfile" id="embedpollfileinput" @change="getFile"> -->
                      <input
                        type="file"
                        style="display: none;"
                        ref="coverImage"
                        accept="image/x-png, image/jpeg"
                        @change="getImageRes()"
                      />
                      <p>
                        <span
                          style="display: inline-block;font-size: 9px;margin-right: 12px;font-weight: normal;"
                        >
                          Allowed file types:
                          <span style="display: inline-block;margin-left: 7px;"
                            >.jpg, .jpeg, .png,</span
                          >
                        </span>
                        <span
                          style="display: inline-block;font-size: 9px;font-weight: normal;"
                        >
                          Maximum upload file size:
                          <span style="display: inline-block;margin-left: 7px;"
                            >3MB</span
                          >
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="row" v-if="showSpinUploadImg">
                    <div class="sk-chase">
                      <div class="sk-chase-dot"></div>
                      <div class="sk-chase-dot"></div>
                      <div class="sk-chase-dot"></div>
                      <div class="sk-chase-dot"></div>
                      <div class="sk-chase-dot"></div>
                      <div class="sk-chase-dot"></div>
                    </div>
                  </div>
                  <div class="row" v-if="bankSlipImage">
                    <img
                      style="margin-top:10px; width: auto; height:100px;"
                      slot="image"
                      :src="bankSlipImage"
                    />
                  </div>
                  <div
                    style="text-align: center;margin-top:10px; margin-bottom:10px;"
                  >
                    <button
                      type="button"
                      class="btn proceed-btn"
                      style="color: black; font-weight: 600; font-size: 18px;"
                      :disabled="!makePayValid"
                      @click="
                        submitBankImageSlip(bankSlipImage, bankTranferObj.bank)
                      "
                    >
                      Make Payment
                    </button>
                    <!-- <button
                    type="button"
                    class="btn proceed-btn"
                    style="color: black; font-weight: 600; font-size: 18px;"
                    :disabled="bankSlipImage=='' || bankTranferObj.bank == '-1'"
                    @click="submitBankImageSlip"
                    >Make Payment</button>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Final Checkout Modal -->
    <!-- <div class="modal fade" id="checkoutModalCenter" tabindex="-1" role="dialog" aria-labelledby="checkoutModalCenterTitle" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered " role="document">
				<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="checkoutModalLongTitle">Checkout Options</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close" style="margin-top:-20px;">
					<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">

					<div class="module">
						<div class="">
							<ul class="block-infos">
								<li class="info1">
									<div class="inner">
										<i class="fa fa-credit-card"></i>
										<div class="info-cont">
											<a href="#">Card Payment</a>
											<p>Use a credit/debit card to complete the transaction</p>
										</div>
									</div>
								</li>  
								<li @click="checkOut()" class="info4">
									<div class="inner">
										<i class="fa fa-money"></i>
										<div class="info-cont">
											<a >CASH ON DELIVERY</a>
											<p>Pay by cash on delivery</p>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
	
				</div>					
				</div>
			</div> 
    </div>-->
  </div>
</template>
<script>
import Domain from "@/mixins/domain";

// @ is an alias to /src
// import ProductTile from '@/components/product/product_tile.vue'
// import UncommonFeatures from '@/components/common/uncommon_features.vue'
// import TrendingCategories from '@/components/category/trending_categories.vue'
import mixinCommon from "@/mixins/APICommon";
import mixinProduct from "@/mixins/buyer/APIProduct";
import mixinProfile from "@/mixins/buyer/APIProfile";
import mixinPromotions from "@/mixins/buyer/APIPromotions";
import VuePhoneNumberInput from "vue-phone-number-input";

import CommonOptionsList from "@/components/common/commonOptionsList.vue";

export default {
  name: "cart",
  mixins: [mixinCommon, mixinProfile, mixinProduct, mixinPromotions],
  components: {
    CommonOptionsList,
    VuePhoneNumberInput,
  },
  data() {
    return {
      voucherSuccess: "",
      voucherValue: 0,
      voucherPrecentage: 0,
      voucherID: "",
      voucherMaxValue: 0,
      voucherMinValue: 0,
      voucherPrecentage: 0,
      voucherError: "",
      voucherCode: "",
      isVoucherActive: false,
      showSpinUploadImg: false,
      activeDiv: 1,
      pamentType: 2,
      selectedBank: {},
      fileUploadFormData: new FormData(),
      image: null,
      subImg: null,
      enableProceed: false,
      bankTranferObj: {
        bank: "1",
      },
      imageUploaded: "@/assets/image/ic_bank_tranfer@2x.png",
      bankOption: [
        // {
        //   key: "1",
        //   value: "Cargills Bank Ltd",
        //   text: "Cargills Bank Ltd",
        //   branch: "Maitland Crescent",
        //   account_name: "SUPREMEPAY (PVT) LIMITED",
        //   account_no: "002950000174"
        // }
        {
          key: "1",
          value: "Bank Of Ceylon(7010)",
          text: "Bank Of Ceylon(7010)",
          branch: "Corporate Branch(660)",
          account_name: "Bank Of Ceylon Trading Account",
          account_no: "0076587128",
        },
      ],
      totalWeight: 0,
      showContent: 1,
      orderLimit: 0,
      profileDetails: {},
      breadcrumb: [
        {
          text: "Cart",
          href: "/buyer-cart",
          active: true,
        },
      ],
      deliveryPartnerData: [
        {
          text: "DHL Standard - Rs.400",
          value: "24d2cs324223422131",
          price: 400,
        },
        { text: "DHL Express - Rs.700", value: "v432q4223422131", price: 700 },
      ],
      Deliveryrates: [],
      selectedDeliveryPartner: [],
      showDeliveryPartners: [],
      temp: {
        fullname: "",
        phonenumber: "",
      },
      fullname: "",
      phonenumber: "",
      billing_fullname: "",
      billing_phone: "",
      delivery_fullname: "",
      delivery_phone: "",
      selectedprovince: "",
      selectedregion: "",
      selectedcity: "",
      selectedcityAgent: "",
      selectedAddressLine1: "",
      selectedAddressLine2: "",
      // selectedpostalcode: "",
      is_biling_address: "",
      countries: [],
      province: [],
      region: [],
      city: [],
      provinceArray: [],
      regionArray: [],
      cityArray: [],
      addressArray: [
        {
          fullname: "",
          phonenumber: "",
          houseno: "",
          country: "",
          province: "",
          region: "",
          city: "",
          postal_code: "",
          is_biling_address: true,
          primary: false,
        },
      ],
      address: {
        fullname: "",
        phonenumber: "",
        houseno: "",
        country: "",
        province: "",
        region: "",
        city: "",
        postal_code: "",
        is_biling_address: true,
        is_shipping_address: true,
        primary: false,
      },
      delivery_address: {
        fullname: "",
        phonenumber: "",
        houseno: "",
        country: "",
        province: "",
        region: "",
        city: "",
        address_line_1: "",
        address_line_2: "",
        postal_code: "",
        is_biling_address: true,
        is_shipping_address: true,
        primary: false,
      },
      billing_address: {
        fullname: "",
        phonenumber: "",
        houseno: "",
        country: "",
        province: "",
        region: "",
        city: "",
        address_line_1: "",
        address_line_2: "",
        postal_code: "",
        is_biling_address: true,
        is_shipping_address: true,
        primary: false,
      },
      isCheck: true,
      multiAddress: true,
      subTotal: 0,
      shippingfee: 0,
      shipping_discount: 0,
      total: 0,
      no_of_cart_items: 0,
      cart_products: [],
      cart_products_card: [],
      cart_products_cash: [],
      cartSavedProductIds: [],
      idObj: {
        ids: [],
      },
      isVisible: true,
      bill_fname: "",
      bill_lname: "",
      bill_address: "",
      showSpan: "",
      showSpan1: "",
      bill_mobile: "",
      changeBillingAddress: true,
      selectAllcheck: true,
      selectAllcheckBoth: false,
      selectAllcheckCard: true,
      selectAllcheckCash: false,
      isBankTrance: false,
      flashitems: {},
      deliverCompany: "",
      buynow: false,
      user_role: 0,
      agent_email: "",
      guest_form: {
        first_name: "",
        last_name: "",
        mobile: "",
        delivery_address: "",
        billing_address: "",
      },
      mobileValid: false,
      tempAddressData: [],
      orderSettings: "",
    };
  },
  computed: {
    page() {
      return this.$route.query.type || null;
    },
    bankSlipImage: function() {
      if (this.image) {
        return this.image;
      } else {
        return "";
      }
    },
    formValid: function() {
      if (this.temp.phonenumber.length >= 9) {
        return false;
      } else {
        return true;
      }
    },
    makePayValid: function() {
      return (
        this.bankSlipImage &&
        this.bankTranferObj.bank != -1 &&
        this.isBankTrance
      );
    },
  },
  watch: {
    // selectedDeliveryPartner: function(val) {
    // 	this.pickDeiveryPrice();
    // },
    page() {
      this.handleBreadcrumb();
      this.loadByOrder();
      this.fetchCartCashLimit();
      this.getOrderLimit();
      this.selectedBank = this.bankOption[0];
    },
    selectAllcheck(newCount, oldCount) {
      //   this.calculateTotalfee();
    },
  },
  mounted() {
    
    this.$refs.inputno.$el.children[0].children[0].children[0].children[0].style.marginTop =
      "0px";
    this.$refs.inputno.$el.children[0].children[0].children[0].style.marginTop =
      "-4px";
    this.$refs.inputno.$el.children[0].children[0].children[1].style.paddingTop =
      "3px";
    this.$refs.inputno.$el.children[1].children[0].children[0].style.paddingTop =
      "3px";
    if (this.$refs.inputno1) {
      this.$refs.inputno1.$el.children[0].children[0].children[0].children[0].style.marginTop =
        "0px";
      this.$refs.inputno1.$el.children[0].children[0].children[0].style.marginTop =
        "-4px";
      this.$refs.inputno1.$el.children[0].children[0].children[1].style.paddingTop =
        "3px";
      this.$refs.inputno1.$el.children[1].children[0].children[0].style.paddingTop =
        "3px";
    }
    if (this.$refs.inputno2) {
      this.$refs.inputno2.$el.children[0].children[0].children[0].children[0].style.marginTop =
        "0px";
      this.$refs.inputno2.$el.children[0].children[0].children[0].style.marginTop =
        "-4px";
      this.$refs.inputno2.$el.children[0].children[0].children[1].style.paddingTop =
        "3px";
      this.$refs.inputno2.$el.children[1].children[0].children[0].style.paddingTop =
        "3px";
    }
    this.$nextTick(function() {
      const mobileNumberInputs = document.querySelectorAll(".input-tel__input");
      const countryContainers = document.querySelectorAll(
        ".select-country-container"
      );
      const countrySelectInputs = document.querySelectorAll(
        ".country-selector__input"
      );
      const countrySelectorFlags = document.querySelectorAll(
        ".country-selector__country-flag"
      );
      const countrySelectorArrows = document.querySelectorAll(
        ".country-selector__toggle"
      );

      mobileNumberInputs.forEach((element) => {
        element.style.boxShadow = "none";
        element.placeholder = "";
        element.style.cursor = "auto";
        element.nextElementSibling.style.display = "none";
        element.style.paddingTop = "0px";
        element.style.lineHeight = "40px";
      });

      countryContainers.forEach((element) => {
        element.style.width = "100px";
        element.style.minWidth = "100px";
        element.style.flex = "0 0 100px";
      });

      countrySelectInputs.forEach((element) => {
        element.style.paddingTop = "0px";
        element.style.lineHeight = "40px";
      });

      countrySelectorFlags.forEach((element) => {
        element.style.top = "18px";
      });

      countrySelectorArrows.forEach((element) => {
        element.style.top = "calc(50% - 13px)";
      });
    });
    /* ---------------------------------------------------
		Accordion to Bonus page
		-------------------------------------------------- */
    $("ul.yt-accordion li").each(function() {
      if ($(this).index() < 0) {
        $(this)
          .children(".accordion-inner")
          .css("display", "none");
      } else {
        $(this)
          .find(".accordion-heading")
          .addClass("active");
      }

      var ua = navigator.userAgent,
        event = ua.match(/iPad/i) ? "touchstart" : "click";
      $(this)
        .children(".accordion-heading")
        .bind(event, function() {
          $(this).addClass(function() {
            if ($(this).hasClass("active")) return "";
            return "active";
          });

          $(this)
            .siblings(".accordion-inner")
            .slideDown(350);
          $(this)
            .parent()
            .siblings("li")
            .children(".accordion-inner")
            .slideUp(350);
          $(this)
            .parent()
            .siblings("li")
            .find(".active")
            .removeClass("active");
        });
    });
  },
  created() {
    this.handleBreadcrumb();
    this.loadByOrder();
    this.fetchCartCashLimit();
    this.getOrderLimit();
    this.selectedBank = this.bankOption[0];
    // this.checkForNoAddress();
  },
  methods: {
    validatePhone(key) {
      if (key === "0") {
        this.guest_form.mobile = "";
      }
      key = key.replace(/^0+/, "");
      key = key.replace(/ /g, "");
      this.guest_form.mobile = key;
      if (key.length == 9) {
        this.changeColor.border = "1px solid red";
        this.mobileValid = true;
      } else if (key.length === 10 || key.length < 10) {
        this.changeColor.border = "1px solid #ccc";
        this.mobileValid = false;
      }
    },
    validatePhone1(key) {
      if (key === "0") {
        this.guest_form.user_home_mobile = "";
      }
      key = key.replace(/^0+/, "");
      key = key.replace(/ /g, "");
      this.guest_form.user_home_mobile = key;
      if (key.length == 9) {
        this.changeColor1.border = "1px solid red";
        this.mobileValid1 = true;
      } else if (key.length === 10 || key.length < 10) {
        this.changeColor1.border = "1px solid #ccc";
        this.mobileValid1 = false;
      }
    },
    clearVoucherData() {
      this.voucherSuccess = "";
      this.voucherValue = 0;
      this.voucherPrecentage = 0;
      this.voucherID = "";
      this.voucherError = "";
      this.isVoucherActive = false;
    },
    pointActiveCheck: function () { 
      // if (this.isPointActive) {
      //   this.$swal({
      //     title: "You can't apply a voucher with Supiri Reward points.",
      //     text:
      //       "If you wish to apply a voucher code, your points applied will be reset. Do you wish to Apply your voucher code?",
      //     type: "warning",
      //     showCancelButton: true,
      //     confirmButtonColor: "#3085d6",
      //     confirmButtonText: "Yes",
      //   }).then((result) => {
      //     // <--
      //     if (result.value) {
      //       this.clearPointData();
      //       this.handleVoucherCheck();
      //     } else {
      //       this.voucherCode = "";
      //     }
      //   });
      // } else {
      this.handleVoucherCheck();
      // }
    },
    handleVoucherCheck: async function () {
      try {
        this.voucherError = "";
        this.voucherSuccess = "";
        this.voucherValue = 0;
        this.voucherPrecentage = 0;
        this.voucherID = "";
        this.voucherMaxValue = null
        this.voucherMinValue = null
        let response = await this.getVoucherCheck(this.voucherCode);
        if (response.active_status == 1) {
          this.voucherError = "";
          this.voucherSuccess = "";

          // Amount Type = 1 - Precentage
          if (response.amount_type == 1) {
            if((this.subTotal + this.shippingfee)<=response.max_value){
              if((this.subTotal + this.shippingfee)>=response.min_value){
                this.voucherMaxValue =response.max_value;
                this.voucherMinValue =response.min_value;
                this.voucherSuccess = "Valid " + response.amount + "% Voucher Code";
                this.$swal.fire({
                  position: "center",
                  type: "success",
                  title: "Voucher code have been added successfully",
                });
                this.voucherPrecentage = response.amount;
                this.voucherID = response._id;
                this.calVoucherPrecentage();
                this.isVoucherActive = true;
              } else {
                this.voucherMinValue =response.min_value;
                // this.voucherPrecentage = response.amount;
                // this.voucherID = response._id;
                // this.calVoucherPrecentage();
                //this.voucherCode = "";
                // if(this.voucherValue ==this.voucherMinValue){
                // this.voucherError = "Your voucher is applicable for a minmum value of Rs. "+this.voucherMinValue;
                this.voucherCode = ""
                this.$swal.fire({
                  position: "center",
                  type: "error",
                  title: "Please note that your voucher is applicable for a minmum value of Rs "+this.voucherMinValue,
                });
                // }else{
                //   this.$swal.fire({
                //   position: "center",
                //   type: "success",
                //   title: "Voucher code have been added successfully",
                //   });
                // }
                this.isVoucherActive = true;
              }
            }else{
              this.voucherMaxValue =response.max_value;
              //this.voucherCode = "";
              // if(this.voucherValue==this.voucherMaxValue){
              // this.voucherError = "Your voucher is applicable for a maximum value of Rs. "+ this.voucherMaxValue
              this.voucherCode = ""
              this.$swal.fire({
                position: "center",
                type: "error",
                title: "Please note that your voucher is applicable for a maximum value of Rs "+this.voucherMaxValue,
              });
              // }else{
              //   this.voucherPrecentage = response.amount;
              //   this.voucherID = response._id;
              //   this.calVoucherPrecentage();
              //   this.$swal.fire({
              //   position: "center",
              //   type: "success",
              //   title: "Voucher code have been added successfully",
              //   });
              // }
              // this.isVoucherActive = true;
            }
            
          }

          // Amount Type = 2 - Fixed Discount
          if (response.amount_type == 2) {
            this.voucherMaxValue =response.max_value;
            this.voucherMinValue =response.min_value;
            if((this.subTotal + this.shippingfee)<=response.max_value){
              if((this.subTotal + this.shippingfee)>=response.min_value){
                this.voucherSuccess ="Valid Rs." + response.amount + " Voucher Code";
                this.$swal.fire({
                  position: "center",
                  type: "success",
                  title: "Voucher code have been added successfully",
                });
                this.voucherValue = response.amount;
                this.voucherID = response._id;
                this.isVoucherActive = true;
              } else{
                this.voucherCode = ""
                // this.voucherError = "Your voucher is applicable for a minmum value of Rs. "+this.voucherMinValue;
                this.$swal.fire({
                  position: "center",
                  type: "error",
                  title: "Please note that your voucher is applicable for a minmum value of Rs "+this.voucherMinValue,
                });
              }
            } else {
              this.voucherCode = ""
              // this.voucherError = "Your voucher is applicable for a maximum value of Rs. "+ this.voucherMaxValue
              this.$swal.fire({
                position: "center",
                type: "error",
                title: "Please note that your voucher is applicable for a maximum value of Rs "+this.voucherMaxValue,
              });
            }

          }
          this.redeemPoint = "";
        }
      } catch (error) {
        console.log(error);
        if(error.data.code==405){
          this.$swal.fire({
            position: "center",
            type: "error",
            title: error.data.message,
          });
          //this.voucherError = error.data.message;
        }
        else{
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Invalid Voucher Code",
          });
        }
        this.voucherSuccess = "";
        this.voucherValue = 0;
        this.voucherPrecentage = 0;
        this.voucherID = "";
        this.voucherCode = ""
        
        this.isVoucherActive = false;
        
      }
    },
    calVoucherPrecentage() {
      if (this.voucherPrecentage != 0 && this.voucherID != "") {
        let discountvale =
          (this.subTotal) * (this.voucherPrecentage / 100);
        //this.voucherValue = discountvale;
         if(discountvale >=this.voucherMaxValue){
          this.voucherValue = this.voucherMaxValue;
        }else{
          this.voucherValue = discountvale;
        }
      }
    },
    changeAgentCity: async function() {
      await this.setCartItems();
      await this.calculateTotalfee();
    },
    changeColor() {
      return "";
    },
    changeColor1() {
      return "";
    },
    setAction: function(val) {
      this.activeDiv = val;
    },
    setMobileNo: function(val) {
      if (val == null) {
        this.temp.phonenumber = "";
      } else {
        if (val.length <= 9) {
          this.temp.phonenumber = val;
        } else {
          this.temp.phonenumber = val.substring(0, val.length - 1);
        }
      }
    },
    proceed: function() {
      this.enableProceed = true;
      if (this.activeDiv == 1) {
        //1 = supiripola, 2= cashon delivery, 3 = bank transfer, 4 = card
        this.pamentType = 1;
        this.checkOut();
      }
      if (this.activeDiv == 2) {
        this.pamentType = 2;
        this.checkOut();
      }
      if (this.activeDiv == 3) {
        this.pamentType = 3;
        this.goToBankTransfer();
        this.enableProceed = false;
      }
      if (this.activeDiv == 4) {
        this.pamentType = 4;
        this.checkOut();
      }
    },
    setbank: function(payload) {
      this.bankOption.forEach((item) => {
        if (payload == item.key) {
          this.selectedBank = item;
        }
      });
    },
    goToBankTransfer: function() {
      // this.showContent = 3;
      $("#addressModalCenter").modal("hide");
      $("#modalBank").modal("show");
    },
    // call mastercard payment gateway function VUE Internal
    // goToPaymentGateway: async function(order_env_number){
    // try{
    // var url = window.location.href;
    // var urlarr = url.split("/");
    // var hostname = urlarr[0] + "//" + urlarr[2];
    // var return_url = hostname+'/buyer-orders?order_env_number='+order_env_number;
    // // CALL mastercard session function in apicommon mixin
    // var obj = {order_id:order_env_number,return_url:return_url}
    // let responseSession = await this.getMastercardSessionID(obj);
    // var totalamount = this.total + this.shippingfee;
    // Checkout.configure({
    // 	merchant: '700193990064',
    // 	session: {
    // 		id: responseSession.session_id
    // 	},
    // 	order: {
    // 		amount: totalamount,
    // 		currency: 'LKR',
    // 		description: 'Sathosa Order',
    // 		id: order_env_number
    // 	},
    // 	interaction: {
    // 		operation: 'PURCHASE',
    // 		merchant: {
    // 		name: 'sathosaapi.supiripola.lk',
    // 		address: {
    // 			line1: 'Level 33, West Tower,',
    // 			line2: 'World Trade Centre, Colombo - 01'
    // 		},
    // 		logo: "https://res.cloudinary.com/persystance-network/image/upload/v1586431751/slt/portal_user/1586431751128.png"
    // 		}
    // 	}
    // });
    // Checkout.showPaymentPage();
    // // Checkout.showLightbox();
    // }catch (error) {
    // throw(error);
    // }
    // },
    // function for public external ipg.html , redirect to ipg.html with order_id, amount and session ID, return url genarate from node API - (in session)
    goToPaymentGatewayPublic: async function(order_env_number, totalAmount) {
      try {
        if (order_env_number && totalAmount) {

          $.redirect("https://ipg.cochchi.lk/payment_form.php", 
          {
              order_id : order_env_number,
              amount : totalAmount,
              bill_to_address_city : this.billing_address.city,
              bill_to_address_country : this.billing_address.country,
              bill_to_address_line1 : this.billing_address.address_line_1,
              bill_to_address_postal_code : this.billing_address.postal_code,
              bill_to_email : this.profileDetails.email,
              bill_to_phone : this.delivery_phone.includes("+")? this.delivery_phone : "+94" + this.delivery_phone,
              bill_to_forename: this.profileDetails.first_name,
              bill_to_surname : this.profileDetails.last_name,
          },
           "POST");
        }
        //   let res = await this.moveHnbIpg(order_env_number , paymentObj);

        // var url = window.location.href;
        // var urlarr = url.split("/");
        // var hostname = urlarr[0] + "//" + urlarr[2];
        // var return_url =
        //   hostname +
        //   "/buyer-orders?success=1&order_env_number=" +
        //   order_env_number;
        // var cancel_url =
        //   hostname +
        //   "/buyer-orders?success=0&order_env_number=" +
        //   order_env_number;
        // // CALL mastercard session function in apicommon mixin
        // var obj = {
        //   order_id: order_env_number,
        //   return_url: return_url,
        //   cancel_url: cancel_url,
        // };
        // let responseSession = await this.getMastercardSessionIDPublic(obj);
        // var session_id = responseSession.session_id;
        // // var amount = this.total + this.shippingfee;
        // var amount = totalAmount;
        // if (order_env_number && session_id && amount) {
        //   window.location.replace(
        //     hostname +
        //       "/ipg.html?order_id=" +
        //       order_env_number +
        //       "&session_id=" +
        //       session_id +
        //       "&amount=" +
        //       amount
        //   );
        // }

      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    // **************************************************************************************************

    pickCoverImage: function() {
      this.$refs.coverImage.click();
      this.isBankTrance = true;
    },
    getImageRes: async function() {
      try {
        this.showSpinUploadImg = true;
        let fileObj = this.$refs.coverImage.files[0];

        // Validate file size
        // if (fileObj.size >= 1024 * 5120) {
        //   this.$swal.fire({
        //     position: "center",
        //     type: "error",
        //     title: "Please upload below 3MB file",
        //     showConfirmButton: false,
        //     timer: 1500,
        //   });
        // } else {
          const fd = new FormData();
          fd.append("image", fileObj);
          let response = await this.putBankImage(fd);
          if (response) {
            this.showSpinUploadImg = false;
            this.image = response.url;
          }
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Pay slip uploaded!",
            showConfirmButton: false,
            timer: 1500,
          });
        // }
      } catch (error) {
        this.showSpinUploadImg = false;
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Submit failed",
          showConfirmButton: false,
          timer: 1500,
        });
        throw error;
      }
    },
    submitBankImageSlip: function(val, val1) {
      this.isBankTrance = false;
      if (val === "" || val1 === "-1") {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Please attach the slip.",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        this.pamentType = 3;
        this.checkOut();
      }
    },
    checkForNoAddress: function() {
      if (this.profileDetails.addresses != undefined) {
        if (this.profileDetails.addresses.length == 0) {
          this.showModal = 2;
          $("#addressModalCenter").modal("show");
        }
      }
    },
    fetchCartCashLimit: async function() {
      try {
        let data = await this.getCartCashLimit();
      } catch (error) {
        throw error;
      }
    },
    checkAllSelected(type) {
      if (type === "cash") {
        let notselected = this.cart_products_cash.filter(function(item) {
          return item.selected == true;
        });
        if (notselected.length === this.cart_products_cash.length) {
          this.selectAllcheckCash = true;
        }
      }
      if (type === "card") {
        let notselected = this.cart_products_card.filter(function(item) {
          return item.selected == true;
        });
        if (notselected.length === this.cart_products_card.length) {
          this.selectAllcheckCard = true;
        }
      }
    },
    switchToCard(item_index) {
      let item_data = this.cart_products_cash[item_index];
      item_data.paymentOption = 2;
      item_data.selected = true;
      this.cart_products_card.push(item_data);
      this.cart_products_cash.splice(item_index, 1);

      this.calculateTotalfee();
    },
    checkCODExcess() {
      if (this.selectAllcheckCash) {
        let total = this.subTotal + this.shippingfee;
        if (total > 10000) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    loadByOrder: async function() {
      if (localStorage.buyer_data) {
        let userdata = JSON.parse(localStorage.buyer_data);
        if (userdata.hasOwnProperty("user_role")) {
          this.user_role = userdata.user_role;
          this.agent_email = userdata.email;
        }
      }

      if (this.user_role == 2) {
        if (localStorage.agent_guestdata) {
          let agentguest_obj = JSON.parse(localStorage.agent_guestdata);
          this.guest_form = agentguest_obj;
          this.selectedregion = agentguest_obj.selectedregion;
          this.selectedprovince = agentguest_obj.selectedprovince;
          this.selectedcityAgent = agentguest_obj.selectedcity;
          await this.changeCountries("5d8b9e5dfb195816aaea61b3", 0);
          await this.changeProvince(this.selectedprovince, 0);
          await this.changeRegion(this.selectedregion, 0);
        }
      } else {
        this.changeCountries("5d8b9e5dfb195816aaea61b3", 0);
      }

      await this.getPersanalInfo();
      await this.handleFalshdeals();
      await this.setCartItems();

      // await this.productAvailByStore();
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb,
      });
    },
    getPersanalInfo: async function() {
      try {
        let response = await this.getProfileBacicInfoNamed();
        this.profileDetails = response;
        localStorage.removeItem("billing_fullname");
        localStorage.removeItem("billing_phone");
        localStorage.removeItem("delivery_fullname");
        localStorage.removeItem("delivery_phone");

        if (this.user_role !== 2) {
          this.checkForNoAddress();
        }

        if (response.addresses.length === 0) {
          this.addressArray = [
            {
              address: "",
              country: "",
              province: "",
              region: "",
              city: "",
              postal_code: "",
              is_biling_address: true,
              primary: false,
            },
          ];
        } else {
          if (
            !response.hasOwnProperty("first_name") ||
            !response.hasOwnProperty("last_name")
          ) {
            this.$swal.fire({
              position: "center",
              type: "error",
              title: "Please Update Your Personal Informaion!",
              showConfirmButton: false,
              timer: 1500,
            });
            this.$router.push("/buyer-profile");
            return;
          }

          this.bill_fname = response.first_name;
          this.bill_lname = response.last_name;
          this.bill_mobile = response.mobile;

          if (localStorage.billing_fullname) {
            this.billing_fullname = localStorage.billing_fullname;
          } else {
            if (this.billing_fullname === "") {
              this.billing_fullname = this.bill_fname + " " + this.bill_lname;
            }
          }

          if (localStorage.billing_phone) {
            this.billing_phone = localStorage.billing_phone;
          } else {
            if (this.billing_phone === "") {
              this.billing_phone = this.bill_mobile;
            }
          }

          if (localStorage.delivery_fullname) {
            this.delivery_fullname = localStorage.delivery_fullname;
          } else {
            if (this.delivery_fullname === "") {
              this.delivery_fullname = this.bill_fname + " " + this.bill_lname;
            }
          }

          if (localStorage.delivery_phone) {
            this.delivery_phone = localStorage.delivery_phone;
          } else {
            if (this.delivery_phone === "") {
              this.delivery_phone = this.delivery_phone;
            }
          }

          if (response.hasOwnProperty("addresses")) {
            for (let index = 0; index < response.addresses.length; index++) {
              if (response.addresses[index].is_shipping_address === true) {
                this.delivery_address = {
                  _id: response.addresses[index]._id,
                  address: response.addresses[index].address,
                  country: response.addresses[index].country.country_name,
                  province: response.addresses[index].province.province_name,
                  region: response.addresses[index].region.region_name,
                  city: response.addresses[index].city.city_name,
                  address_line_1: response.addresses[index].address_line_1,
                  address_line_2: response.addresses[index].address_line_2,
                  postal_code: response.addresses[index].postal_code,
                  is_biling_address:
                    response.addresses[index].is_biling_address,
                  is_shipping_address: true,
                };
              }

              if (response.addresses[index].is_biling_address === true) {
                this.billing_address = {
                  address: response.addresses[index].address,
                  country: response.addresses[index].country.country_name,
                  province: response.addresses[index].province.province_name,
                  region: response.addresses[index].region.region_name,
                  city: response.addresses[index].city.city_name,
                  address_line_1: response.addresses[index].address_line_1,
                  address_line_2: response.addresses[index].address_line_2,
                  postal_code: response.addresses[index].postal_code,
                  is_biling_address: true,
                  is_shipping_address:
                    response.addresses[index].is_shipping_address,
                };
              }

              await this.changeCountries(
                response.addresses[index].country._id,
                index
              );
              await this.changeProvince(
                response.addresses[index].province._id,
                index
              );
              await this.changeRegion(
                response.addresses[index].region._id,
                index
              );
              if (response.addresses[index].is_biling_address === true) {
                this.bill_address = response.addresses[index]._id;
              }
              this.addressArray = response.addresses;
            }
            this.addressArray = response.addresses;
          }

          if (this.delivery_phone === "") {
            this.delivery_phone = this.profileDetails.mobile;
          }
          this.getDeliveryrates();
        }
      } catch (error) {
        throw error;
      }
    },
    getDeliveryrates: async function() {
      try {
        //Check if all the items not available and Deselected
        var notavailitems = this.cart_products.filter(function(element) {
          return element.not_available_zone == true;
        });

        if (notavailitems.length == this.cart_products.length) {
          for (let index = 0; index < this.cart_products.length; index++) {
            const element = this.cart_products[index];
            delete element.not_available_zone;
            element.disable = false;
            element.selected = true;
          }
        }

        let response = "";

        this.cart_products;
        let pid_obj = {
          product: [],
        };

        let agentpid_obj = {
          city: this.selectedcityAgent,
          product: [],
        };

        let cityid = "";

        if (this.tempAddressData.hasOwnProperty("city")) {
          cityid = this.tempAddressData.city;
        } else {
          this.addressArray.forEach((element) => {
            if (element.is_shipping_address == true) {
              cityid = element.city._id;
            }
          });
        }

        pid_obj.city = cityid;

        for (let index = 0; index < this.cart_products.length; index++) {

          if (this.cart_products[index].selected) {
            pid_obj.product.push({
              product_id: this.cart_products[index].item_id,
              qty: this.cart_products[index].primary_product.selected_qty,
              zone: this.cart_products[index].zone,
              city: this.delivery_address.city,
              // sku: this.cart_products[index].sku,

              // merchant_id: this.cart_products[index].merchant_id,
              simple_product_id: this.cart_products[index].primary_product._id

            });

            if (this.user_role == 2) {
              agentpid_obj.product.push({
                product_id: this.cart_products[index].item_id,
                qty: this.cart_products[index].primary_product.selected_qty,
                zone: this.cart_products[index].zone,
                city: this.delivery_address.city,
                // sku: this.cart_products[index].sku,
                // merchant_id: this.cart_products[index].merchant_id,
              simple_product_id: this.cart_products[index].primary_product._id

              });
            }
          }
        }

        if (pid_obj.product.length == 0) {
          return;
        }

        if (this.user_role == 2) {
          response = await this.fetchDeliveryRatesByPIds(agentpid_obj);
          // response = await this.fetchAgentDeliveryRatesByPIds(agentpid_obj);
        } else {
          response = await this.fetchDeliveryRatesByPIds(pid_obj);
          // response = await this.fetchDeliveryRatesByPIds(pid_obj);
        }
        // if (this.user_role == 2) {
        //   response = await this.postAvailableByStore(agentpid_obj);
        // } else {
        //   response = await this.postAvailableByStore(pid_obj);
        // }

        this.cart_products.forEach((item) => {
          delete item.not_available_zone;
          item.disable = false;
        });

        // for (let index = 0; index < response.length; index++) {
        //   const element = response[index];

        //   if (element.product_id != "") {

        //     this.cart_products.forEach((item) => {
        //       if (element.product_id != element.old_product_id) {
        //         if (element.old_product_id == item.item_id) {
        //           item.item_id = element.product_id;
        //           item.primary_product._id = element.product_data.items[0]._id;
        //           item.primary_product.quantity =
        //             element.product_data.items[0].quantity;

        //           if (
        //             item.primary_product.selected_qty >
        //             element.product_data.items[0].quantity
        //           ) {
        //             item.primary_product.selected_qty =
        //               element.product_data.items[0].quantity;
        //           }
        //         }
        //       }
        //       item.store_name =
        //         element.product_data.store.store_name

        //       delete item.not_available_zone;
        //       item.disable = false;
        //     });
        //   }

        //   if (element.product_id == "") {
        //     element.product_id = element.old_product_id;
        //     this.cart_products.forEach((item) => {
        //       if (item.item_id == element.old_product_id) {
        //         item.not_available_zone = true;
        //         item.disable = true;
        //         this.selectAllcheck = false;
        //         item.store_name = "Not Available";
        //       }
        //     });
        //   }
        // }

        this.Deliveryrates = response;

        // if (this.user_role == 2) {
        //   for (let index = 0; index < this.Deliveryrates.length; index++) {
        //     const element = this.Deliveryrates[index];
        //     if (element.deliver_partners.length == 0) {
        //       this.cart_products[index].disable = true;
        //       this.cart_products[index].not_available_zone = true;
        //       this.selectAllcheck = false;
        //     } else {
        //       this.selectAllcheck = true;
        //       this.cart_products[index].disable = false;
        //       delete this.cart_products[index].not_available_zone;
        //     }
        //   }
        // }

        if (this.Deliveryrates.length > 0) {
          for (let index = 0; index < this.Deliveryrates.length; index++) {
            this.cart_products.forEach((element) => {
              if (this.Deliveryrates[index].product_id == element.item_id) {
                element.deliver_charge = Math.ceil(
                  this.Deliveryrates[index].deliver_charge
                );
                element.deliver_discount = Math.ceil(
                  this.Deliveryrates[index].deliver_discount
                );

                // if(this.Deliveryrates[index].deliver_partners.length == 0){
                // 	element.not_available_zone = true;
                // 	element.disable = true
                // 	element.selected = false
                // } else {
                // 	element.not_available_zone = false;
                // 	element.disable = false
                // }
              }
            });
          }
        }
        for (let index = 0; index < this.Deliveryrates.length; index++) {
          // if(localStorage.selectedDeliveryPartner){
          // 	this.selectedDeliveryPartner = JSON.parse(localStorage.selectedDeliveryPartner);
          // } else {
          // this.selectedDeliveryPartner.push(this.Deliveryrates[index].product_id + "_" + this.Deliveryrates[index].deliver_partners[0].deliver_rate_id + "_Standard");
          this.selectedDeliveryPartner.push(
            this.Deliveryrates[index].product_id + "_Standard"
          );
          // }

          // this.pickDeiveryPrice(this.Deliveryrates[index].product_id);
        }
        localStorage.setItem(
          "selectedDeliveryPartner",
          JSON.stringify(this.selectedDeliveryPartner)
        );
        this.calculateShippingfee();
        this.getLimitQtyDetails();
      } catch (error) {
        throw error;
      }
    },
    filterDeliveryratesByPId(pid) {
      var array = this.Deliveryrates.filter(function(delrates) {
        return delrates.product_id === pid;
      });

      let delrateArray = [];

      for (let index = 0; index < array[0].deliver_partners.length; index++) {
        var obj = {};

        obj.text =
          array[0].deliver_partners[index].deliver.company_name +
          " Standard - Rs." +
          array[0].deliver_partners[index].deliver_standard_rate;
        obj.value =
          pid +
          "_" +
          array[0].deliver_partners[index].deliver_rate_id +
          "_Standard";
        obj.price = array[0].deliver_partners[index].deliver_standard_rate;

        delrateArray.push(obj);

        if (array[0].deliver_partners[index].deliver_special_rate) {
          var obj = {};

          obj.text =
            array[0].deliver_partners[index].deliver.company_name +
            " Express - Rs." +
            array[0].deliver_partners[index].deliver_special_rate;
          obj.value =
            pid +
            "_" +
            array[0].deliver_partners[index].deliver_rate_id +
            "_Express";
          obj.price = array[0].deliver_partners[index].deliver_special_rate;

          delrateArray.push(obj);
        }
      }

      return delrateArray;
    },
    onClickDeliveryPartner(value) {
      this.$set(this.selectedDeliveryPartner, value.index, value.value);
      this.cart_products[value.index].selectedDeliveryPartner = value.value;
      this.$set(this.showDeliveryPartners, value.index, false);
      this.calculateShippingfee();
    },
    SetshowDeliveryPartners(index) {
      this.showDeliveryPartners = [];
      this.$set(this.showDeliveryPartners, index, true);
    },
    pickDeiveryPrice: function(pid, index = -1) {
      if (this.user_role != 1) {
        for (let index = 0; index < this.Deliveryrates.length; index++) {
          const element = this.Deliveryrates[index];
          if (element.product_id === pid) {
            if (
              element.deliver_partners.length > 0 &&
              element.deliver_partners[0].hasOwnProperty("deliver")
            ) {
              if (this.cart_products[index].is_free_shipping) {
                return (
                  element.deliver_partners[0].deliver.company_name +
                  " : Free Delivery"
                );
              } else {
                return (
                  element.deliver_partners[0].deliver.company_name +
                  " : Rs. " +
                  this.cart_products[index].deliver_charge
                );
              }
            } else {
              return "Not Available";
            }
          }
        }
      } else {
        for (let index = 0; index < this.Deliveryrates.length; index++) {
          const element = this.Deliveryrates[index];
          if (element.product_id === pid) {
            if (
              element.deliver_partners.length > 0 &&
              element.deliver_partners[0].hasOwnProperty("deliver")
            ) {
              if (this.cart_products[index].is_free_shipping) {
                return (
                  element.deliver_partners[0].deliver.company_name +
                  " : Free Delivery"
                );
              } else {
                return element.deliver_partners[0].deliver.company_name; // + " : Rs. " + (this.cart_products[index].deliver_charge ) ;
              }
            } else {
              return "Not Available";
            }
          }
        }
      }

      for (let index = 0; index < this.cart_products.length; index++) {
        if (this.cart_products[index].item_id === pid) {
          if (this.cart_products[index].is_free_shipping) {
            return (
              this.cart_products[index].delivery_company + " : Free Delivery"
            );
          } else {
            return this.cart_products[index].delivery_company; // + " : Rs. " + (this.cart_products[index].deliver_charge ) ;
          }
        }
      }

      return " No Information : Rs. 0";

      return this.cart_products[index].delivery_company; // + " : Rs. " + this.cart_products[index].deliver_charge ;

      let selectedDeliveryPartner = this.cart_products[index]
        .selectedDeliveryPartner;
      // var arraySelecteddelrate =  this.selectedDeliveryPartner.filter(function(selecteddelrates) {
      // 	var check = selecteddelrates.includes(pid);
      // 	return check == true;
      // });
      if (!this.cart_products[index].selectedDeliveryPartner) {
        selectedDeliveryPartner = this.selectedDeliveryPartner[index];
      }
      let did = "";
      let dmethod = "";

      if (selectedDeliveryPartner != undefined) {
        let pickvalue = selectedDeliveryPartner;
        var res = pickvalue.split("_");
        did = res[1];
        dmethod = res[2];
      } else {
        return " ";
      }

      var delarray = this.Deliveryrates.filter(function(delrates) {
        return delrates.product_id === pid;
      });

      var delpartnerarray = delarray[0].deliver_partners.filter(function(
        delpartner
      ) {
        return delpartner.deliver_rate_id === did;
      });

      if (delpartnerarray.length > 0) {
        if (dmethod === "Standard") {
          return (
            delpartnerarray[0].deliver.company_name +
            " Standard - Rs. " +
            delpartnerarray[0].deliver_standard_rate
          );
        } else {
          return (
            delpartnerarray[0].deliver.company_name +
            " Express - Rs. " +
            delpartnerarray[0].deliver_special_rate
          );
        }
      } else {
        return " ";
      }
      return " ";
    },
    // pickDeiveryPriceValue: function(index) {
    //   var arraySelecteddelrate = this.selectedDeliveryPartner.filter(function(
    //     selecteddelrates
    //   ) {
    //     var check = selecteddelrates.includes(index);
    //     return check == true;
    //   });

    //   let did = "";
    //   let dmethod = "";

    //   if (arraySelecteddelrate.length > 0) {
    //     let pickvalue = arraySelecteddelrate[0];
    //     var res = pickvalue.split("_");
    //     did = res[1];
    //     dmethod = res[2];
    //   } else {
    //     return 0;
    //   }

    //   var delarray = this.Deliveryrates.filter(function(delrates) {
    //     return delrates.product_id === index;
    //   });

    //   var delpartnerarray = delarray[0].deliver_partners.filter(function(
    //     delpartner
    //   ) {
    //     return delpartner.deliver_rate_id === did;
    //   });

    //   if (delpartnerarray.length > 0) {
    //     if (dmethod === "Standard") {
    //       return delpartnerarray[0].deliver_standard_rate;
    //     } else {
    //       return delpartnerarray[0].deliver_special_rate;
    //     }
    //   } else {
    //     return 0;
    //   }
    //   return 0;
    // },
    calculatePackageWeight() {
      this.totalWeight = 0;
      for (let index = 0; index < this.cart_products.length; index++) {
        if (this.cart_products[index].selected) {
          if (this.cart_products[index].package_weight != undefined) {
            this.totalWeight =
              this.totalWeight +
              parseFloat(
                this.cart_products[index].package_weight *
                  this.cart_products[index].primary_product.selected_qty
              );
          }
        }
      }
      this.totalWeight = this.totalWeight.toFixed(3);
    },
    calculateShippingfee() {
      let fee = 0;
      let discount_fee = 0;
      for (let index = 0; index < this.cart_products.length; index++) {
        if (this.cart_products[index].selected) {
          if (!this.cart_products[index].is_free_shipping) {
            fee = fee + Math.ceil(this.cart_products[index].deliver_charge);
            discount_fee =
              discount_fee +
              Math.ceil(this.cart_products[index].deliver_discount);
          }
        }
      }
      this.shippingfee = fee;
      this.shipping_discount = discount_fee;
      this.calVoucherPrecentage();
    },
    calculateTotalfee: async function() {
      this.getDeliveryrates();

      let itemnotchecked = true;

      let total = this.cart_products.reduce(function(total, item) {
        if (item.selected && !item.disable) {
          return total + item.itemTotal;
        } else {
          itemnotchecked = false;
          return total;
        }
      }, 0);

      this.subTotal = total;
      this.total = total;

      if (!itemnotchecked) {
        this.selectAllcheck = false;
      } else {
        this.selectAllcheck = true;
      }

      this.calculateShippingfee();
      this.calculatePackageWeight();
    },
    handleFalshdeals: async function() {
      try {
        let flashdata = await this.getFlashDeals();
        if (flashdata.time_now) {
          this.flashitems = flashdata;
        } else {
          this.flashitems = {};
        }
      } catch (error) {
        throw error;
      }
    },
    handleAvailableForZone: async function(pid) {
      try {
        if (!localStorage.buyer_token) {
          return;
        }
        if (localStorage.buyer_token !== null) {
          let response = await this.getAvailableForZone(pid);
          if (response.success == 1) {
            return true;
          } else {
            return false;
          }
        }
      } catch (error) {
        return false;
        // this.zoneBuyAllowed = false;
        // this.zoneBuyAllowed_Message = error.message;
      }
    },
    setCartItems: async function() {
      if (this.$route.query.type) {
        if (this.$route.query.type == "buynow") {
          this.buynow = true;
        } else {
          this.buynow = false;
        }
      } else {
        this.buynow = false;
      }
      let productIDArray = [];
      if (this.buynow) {
        productIDArray = JSON.parse(
          localStorage.getItem("cart_product_ids_checkout")
        );
      } else {
        productIDArray = JSON.parse(localStorage.getItem("cart_product_ids"));
      }

      let self = this;
      this.cart_products = productIDArray.ids;
      this.cart_products = productIDArray.ids.map((obj) => {
        obj.selected = true;
        obj.itemTotal =
          obj.primary_product.special_price * obj.primary_product.selected_qty;
        delete obj.flash_dealid;
        if (this.flashitems.now.hasOwnProperty("products")) {
          let flashproducts = this.flashitems.now.products;
          for (let index = 0; index < flashproducts.length; index++) {
            let fitem_sid = flashproducts[index].simple_product._id;
            let cartitem_sid = obj.primary_product._id;

            if (fitem_sid === cartitem_sid) {
              obj.flash_dealid = flashproducts[index].flash_deal;
            }
          }
        }

        return obj;
      });

      this.cart_products_card = productIDArray.ids.filter((obj) => {
        obj.selected = true;
        obj.itemTotal =
          obj.primary_product.special_price * obj.primary_product.selected_qty;
        delete obj.flash_dealid;
        if (this.flashitems.now.hasOwnProperty("products")) {
          let flashproducts = this.flashitems.now.products;
          for (let index = 0; index < flashproducts.length; index++) {
            let fitem_sid = flashproducts[index].simple_product._id;
            let cartitem_sid = obj.primary_product._id;

            if (fitem_sid === cartitem_sid) {
              obj.flash_dealid = flashproducts[index].flash_deal;
            }
          }
        }
        if (obj.paymentOption === 2) {
          // CARD 1
          return obj;
        }
      });

      this.cart_products_cash = productIDArray.ids.filter((obj) => {
        obj.itemTotal =
          obj.primary_product.special_price * obj.primary_product.selected_qty;
        delete obj.flash_dealid;
        if (this.flashitems.now.hasOwnProperty("products")) {
          let flashproducts = this.flashitems.now.products;
          for (let index = 0; index < flashproducts.length; index++) {
            let fitem_sid = flashproducts[index].simple_product._id;
            let cartitem_sid = obj.primary_product._id;

            if (fitem_sid === cartitem_sid) {
              obj.flash_dealid = flashproducts[index].flash_deal;
            }
          }
        }
        if (obj.paymentOption === 1) {
          obj.selected = false;
          // CASH 2
          return obj;
        }
      });

      if (this.cart_products_card.length === 0) {
        if (this.cart_products_cash.length >= 0) {
          this.selectAllcheckCard = false;
          this.selectAllcheckCash = true;

          this.selectAllItems_CardCash(2);
        }
      } else {
        this.selectAllcheckCard = true;
        this.selectAllcheckCash = false;

        this.selectAllItems_CardCash(1);
      }

      if (this.user_role !== 2) {
        for (let index = 0; index < this.cart_products.length; index++) {
          let pidz = this.cart_products[index].item_id;
          let zoneavail = await this.handleAvailableForZone(pidz);
          if (!zoneavail) {
            this.cart_products[index].disable = true;
            this.cart_products[index].not_available_zone = true;
            this.selectAllcheck = false;
            this.cart_products[index].selected = false;
          } else {
            delete this.cart_products[index].not_available_zone;
          }
        }
      }

      this.calculateTotalfee();

      this.no_of_cart_items = this.cart_products.length;

      this.checkAvailableStock();
    },
    processToCheckOut: function(index, obj) {
      // alert("processToCheckOut");
    },
    removeCartItems: async function(itemObj, index) {
      if (this.voucherCode === "") {
        let tempTableData = this.cart_products;

        for (let index = 0; index < tempTableData.length; index++) {
          if (itemObj.item_id === tempTableData[index].item_id) {
            tempTableData.splice(index, 1);
          }
        }

        this.cart_products = tempTableData;
        this.idObj.ids = [];

        if (this.cart_products.length === 0) {
          this.idObj.ids = [];
          localStorage.setItem("cart_product_ids", JSON.stringify(this.idObj));
        } else {
          this.cart_products.map((obj) => {
          this.idObj.ids.push(obj);
          localStorage.setItem("cart_product_ids", JSON.stringify(this.idObj));
        });
      }

      this.no_of_cart_items = this.idObj.ids.length;
      this.setCartItems();
      this.calculateTotalfee();
      } else {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "You can not remove items after adding a voucher code.",
        });
      }

      if (this.no_of_cart_items === 0) {
        this.$router.push("/") ;
      }
      // } else {
      // 		let tempTableData = this.cart_products;
      // 		tempTableData.splice(index, 1);
      // 		this.cart_products = tempTableData
      // 		this.idObj.ids = []
      // 		localStorage.setItem("cart_product_ids", JSON.stringify(this.idObj));
      // 		this.no_of_cart_items = this.idObj.ids.length;
      // 		this.setCartItems();
      // 		this.calculateTotalfee();
      // }
    },
    changeQty: function(item, maxxVal) {
      item.primary_product.selected_qty = parseInt(
        item.primary_product.selected_qty
      );
      if (isNaN(item.primary_product.selected_qty)) {
        item.primary_product.selected_qty = 1;
      }

      if (item.primary_product.selected_qty >= maxxVal) {
        item.primary_product.selected_qty = maxxVal;
      }

      if (item.primary_product.selected_qty < 1) {
        item.primary_product.selected_qty = 1;
      }

      this.cart_products = this.cart_products.map((obj) => {
        if (obj.item_id === item.item_id) {
          obj.itemTotal =
            obj.primary_product.special_price *
            item.primary_product.selected_qty;
        }
        obj.total = obj.itemTotal + obj.itemTotal;
        return obj;
      });

      this.calculateTotalfee();
    },
    quantityDown: function(item, val, minval) {
      if (this.voucherCode === "") {
        val = parseInt(val);
        if (val > minval) {
          if (val === 1) {
            item.primary_product.selected_qty = 1;
            return;
          }
  
          this.cart_products = this.cart_products.map((obj) => {
            if (obj.item_id === item.item_id) {
              item.primary_product.selected_qty = val - 1;
              obj.itemTotal =
                obj.primary_product.special_price *
                item.primary_product.selected_qty;
            }
            obj.total = obj.itemTotal + obj.itemTotal;
            return obj;
          });
          this.showSpan = "";
          this.showSpan1 = "";
        } else {
          this.showSpan = item.primary_product._id;
        }
  
        this.calculateTotalfee();
      }else {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "You can not change quantity after adding a voucher code.",
        });
      }
    },
    getOrderLimit: async function() {
      try {
        let response = await this.getOrderLimitApi();
        if (response) {
          this.orderLimit = response.order_limit;
          this.orderSettings = response;
        }
      } catch (e) {
        throw e;
      }
    },
    // start
    quantityUp: function(item, val, maxxVal, val1) {
      // console.log("this.cart_products",this.cart_products);
      if (this.voucherCode === "") {
        val = parseInt(val);
        if (val < maxxVal && val < val1) {
          if (val === maxxVal) {
            item.primary_product.selected_qty = maxxVal;
            return;
          }

          this.cart_products = this.cart_products.map((obj) => {
            if (obj.item_id === item.item_id) {
              item.primary_product.selected_qty = val + 1;
              obj.itemTotal =
                obj.primary_product.special_price *
                item.primary_product.selected_qty;
            }
            obj.total = obj.itemTotal + obj.itemTotal;
            return obj;
          });
          this.showSpan = "";
          this.showSpan1 = "";
        } else {
          this.showSpan1 = item.primary_product._id;
        }

        if (val1 - val === 0) {
          this.showSpan1 = 0;
        }

        this.calculateTotalfee();
      } else {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "You can not change quantity after adding a voucher code.",
        });
      }
    },
    changeCountries: async function(event, index, useraction = false) {
      try {
        let response = await this.getProvince(event);
        this.province = response;

        this.$set(this.provinceArray, index, response);
        this.regionArray[index] = [];
        this.cityArray[index] = [];

        // this.provinceArray[index] = response;

        // // RESETING
        if (Object.keys(response).length === 0 || useraction === true) {
          // this.addressArray[index].province = "";
          // this.addressArray[index].region = "";
          // this.addressArray[index].city = "";
        }
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    getLimitQtyDetails: async function() {
      try {
        for (let x = 0; x < this.cart_products.length; x++) {
          let res = await this.getProductInfo(this.cart_products[x].item_id);
          this.cart_products[x].max_limit = res.product.max_limit;
          this.cart_products[x].min_limit = res.product.min_limit;
        }
      } catch (error) {
        throw error;
      }
    },
    changeProvince: async function(event, index, useraction = false) {
      try {
        let response = await this.getRegion(event);
        this.region = response;

        this.$set(this.regionArray, index, response);

        // RESETING
        this.cityArray[index] = [];
        if (Object.keys(response).length === 0 || useraction === true) {
          // this.addressArray[index].region = "";
          // this.addressArray[index].city = "";
          this.selectedregion = "";
          this.selectedcity = "";
        }
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    changeRegion: async function(event, index, useraction = false) {
      try {
        let response = await this.getCity(event);
        this.city = response;

        this.$set(this.cityArray, index, response);

        // RESETING
        if (Object.keys(response).length === 0 || useraction === true) {
          // this.addressArray[index].city = "";
          this.selectedcity = "";
        }
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    clickButton: function(val, tickindex) {
      if (this.addressArray[tickindex].is_biling_address === false) {
        if (this.addressArray.length < 2) {
          this.addNewClick();
          this.changeCountries("5d8b9e5dfb195816aaea61b3", 1);
        }
      }
      for (let index = 0; index < this.addressArray.length; index++) {
        if (val === false) {
          if (tickindex != index) {
            this.addressArray[index].is_biling_address = true;
          }
        } else {
          if (tickindex != index) {
            this.addressArray[index].is_biling_address = false;
          }
        }
      }

      if (val === true) {
        this.isCheck = false;
        this.multiAddress = false;
      } else {
        this.isCheck = true;
        this.multiAddress = true;
      }
    },
    addNewClick: function() {
      if (this.addressArray.length <= 1) {
        if (this.valIndex === 0) {
          this.addressArray[0].primary = true;
        }
        this.valIndex = this.valIndex + 1;
        this.addressArray.push(this.address);
      }
    },
    DeleteClick: async function(index, itemid) {
      this.addressArray.splice(index, 1);
      this.provinceArray.splice(index, 1);
      this.addressArray[0].is_biling_address = true;
    },
    pushCart() {
      this.$router.push("/buyer-cart") ;
    },
    validateGuestData() {
      if (this.guest_form.first_name == "") {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Enter First Name!",
          showConfirmButton: false,
          timer: 1500,
        });
        return false;
      }
      if (!this.mobileValid) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Enter Valid Phone Number!",
          showConfirmButton: false,
          timer: 1500,
        });
        return false;
      }
      if (this.selectedcityAgent == "") {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Select A Valid City!",
          showConfirmButton: false,
          timer: 1500,
        });
        return false;
      }
      if (this.guest_form.delivery_address == "") {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Enter Delivery Address",
          showConfirmButton: false,
          timer: 1500,
        });
        return false;
      }

      return true;
    },
    handdleCheckOut: function() {
      if (this.cart_products.length <= 0) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "No Items In Cart!",
          showConfirmButton: false,
          timer: 1500,
        });

        return;
      }

      if (this.user_role == 2) {
        let valid = this.validateGuestData();
        if (!valid) {
          return;
        }
      }

      this.showContent = 2;

      let no_OfSelecteditems = 0;

      for (let index = 0; index < this.cart_products.length; index++) {
        if (this.cart_products[index].selected) {
          no_OfSelecteditems = no_OfSelecteditems + 1;
        }
      }

      if (no_OfSelecteditems === 0) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "No Items Selected!",
          showConfirmButton: false,
          timer: 1500,
        });

        return;
      }

      $("#addressModalCenter").modal("show");

      this.idObj.ids = [];

      let exceedfound = this.checkCODExcess();

      // alert(exceedfound);

      this.cart_products.map((obj) => {
        if (exceedfound) {
          if (obj.paymentOption == 2) {
            obj.selected = true;
          }

          obj.paymentOption = 2;
        }

        if (this.selectAllcheckBoth) {
          obj.paymentOption = 2;
        }

        if (obj.selected) {
          this.idObj.ids.push(obj);
          localStorage.setItem(
            "cart_product_ids_checkout",
            JSON.stringify(this.idObj)
          );
        }
      });
      localStorage.setItem(
        "selectedDeliveryPartner",
        JSON.stringify(this.selectedDeliveryPartner)
      );

      // this.$router.push('buyer-checkout').catch(err => {});
    },
    pushToItem(item_id) {
      this.$router.push({ path: "product", query: { pid: item_id } });
    },
    AddressNamePhone() {
      if (this.changeBillingAddress) {
        this.billing_fullname = this.fullname;
        this.billing_phone = this.phonenumber;

        localStorage.setItem("billing_fullname", this.billing_fullname);
        localStorage.setItem("billing_phone", this.billing_phone);
      } else {
        this.delivery_fullname = this.fullname;
        this.delivery_phone = this.phonenumber;

        localStorage.setItem("delivery_fullname", this.delivery_fullname);
        localStorage.setItem("delivery_phone", this.delivery_phone);
      }
    },
    changeAddress: async function(type) {
      this.showContent = 1;
      if (type === "billing") {
        this.changeBillingAddress = true;
        for (let index = 0; index < this.addressArray.length; index++) {
          if (this.addressArray[index].is_biling_address === true) {
            await this.changeProvince(this.addressArray[index].province._id, 0);
            await this.changeRegion(this.addressArray[index].region._id, 0);

            this.selectedprovince = this.addressArray[index].province._id;
            this.selectedregion = this.addressArray[index].region._id;
            this.selectedcity = this.addressArray[index].city._id;
            this.selectedAddressLine1 = this.addressArray[index].address_line_1;
            this.selectedAddressLine2 = this.addressArray[index].address_line_2;
            // this.selectedpostalcode = this.addressArray[index].postal_code;
          }
        }

        if (this.billing_fullname === "") {
          this.billing_fullname = this.bill_fname + " " + this.bill_lname;
        }

        if (this.billing_phone === "") {
          this.billing_phone = this.bill_mobile;
        }
        this.temp.fullname = this.billing_fullname;
        this.temp.phonenumber = this.billing_phone.substr(
          3,
          this.billing_phone.length - 1
        );
        // this.fullname = this.billing_fullname;
        // this.phonenumber = this.billing_phone;
      } else {
        this.changeBillingAddress = false;

        if (this.tempAddressData.hasOwnProperty("city")) {
          await this.changeProvince(this.tempAddressData.province, 0);
          await this.changeRegion(this.tempAddressData.region, 0);

          this.selectedprovince = this.tempAddressData.province;
          this.selectedregion = this.tempAddressData.region;
          this.selectedcity = this.tempAddressData.city;
          this.selectedAddressLine1 = this.tempAddressData.address_line_1;
          this.selectedAddressLine2 = this.tempAddressData.address_line_2;
        } else {
          for (let index = 0; index < this.addressArray.length; index++) {
            if (this.addressArray[index].is_shipping_address === true) {
              await this.changeProvince(
                this.addressArray[index].province._id,
                0
              );
              await this.changeRegion(this.addressArray[index].region._id, 0);

              this.selectedprovince = this.addressArray[index].province._id;
              this.selectedregion = this.addressArray[index].region._id;
              this.selectedcity = this.addressArray[index].city._id;
              this.selectedAddressLine1 = this.addressArray[
                index
              ].address_line_1;
              this.selectedAddressLine2 = this.addressArray[
                index
              ].address_line_2;
              // this.selectedpostalcode = this.addressArray[index].postal_code;
            }

            if (this.delivery_fullname === "") {
              this.delivery_fullname = this.bill_fname + " " + this.bill_lname;
            }

            if (this.delivery_phone === "") {
              this.delivery_phone = this.profileDetails.mobile;
            }

            // this.fullname = this.delivery_fullname;
            // this.phonenumber = this.delivery_phone;
            this.temp.fullname = this.delivery_fullname;
            this.temp.phonenumber = this.billing_phone.substr(
              3,
              this.billing_phone.length - 1
            );
          }
        }
      }
      $("#addressModalCenter").modal("show");
    },
    checkOut: async function() {
      let items = [];

      await this.handleFalshdeals();

      for (let index = 0; index < this.cart_products.length; index++) {
        if (this.cart_products[index].flash_dealid) {
          if (!this.flashitems.now.products) {
            this.cart_products[index].selected = false;
            delete this.cart_products[index].flash_dealid;
          } else {
            delete this.cart_products[index].flash_dealid;
            let flashproducts = this.flashitems.now.products;

            for (let index2 = 0; index2 < flashproducts.length; index2++) {
              let fitem_sid = flashproducts[index2].simple_product._id;
              let cartitem_sid = this.cart_products[index].primary_product._id;

              if (fitem_sid === cartitem_sid) {
                this.cart_products[index].flash_dealid =
                  flashproducts[index2].flash_deal;
              }
            }
          }
        }

        if (this.cart_products[index].selected == true) {
          let self = this;
          var arraySelecteddelrate = this.selectedDeliveryPartner.filter(
            function(selecteddelrates) {
              var check = selecteddelrates.includes(
                self.cart_products[index].item_id
              );
              return check == true;
            }
          );
          let did = "";
          let dmethod = "";

          if (arraySelecteddelrate.length > 0) {
            let pickvalue = arraySelecteddelrate[0];
            var res = pickvalue.split("_");
            did = res[1];
            dmethod = res[2];
          } else {
            // return " ";
          }

          var delarray = this.Deliveryrates.filter(function(delrates) {
            return delrates.product_id === self.cart_products[index].item_id;
          });

          // var delpartnerarray = delarray[0].deliver_partners.filter(function(
          //   delpartner
          // ) {
          //   return delpartner.deliver_rate_id === did;
          // });

          let dtype = 1;

          if (dmethod === "Standard") {
            dtype = 1;
          } else {
            dtype = 2;
          }
          let delivery_partner = "";
          let delivery_rate = "";

          let skip = false;
          this.Deliveryrates.forEach((element) => {
            if (element.product_id === this.cart_products[index].item_id) {
              //skip the items that does not have delivery partners
              if (element.deliver_partners.length == 0) {
                skip = true;
              } else {
                delivery_partner = element.deliver_partners[0].deliver._id;
                delivery_rate = element.deliver_partners[0]._id;
              }
            }
          });

          if (skip) {
            continue;
          }

          let coupon_id = "";
          if (this.cart_products[index].primary_product.coupon_id) {
            let coupon_id = this.cart_products[index].primary_product.coupon_id
          }

          let itemobj = {
            simple_product_id: this.cart_products[index].primary_product._id,
            delivery_partner: delivery_partner,
            delivery_rate: delivery_rate,
            delivery_type: 1,
            qty: this.cart_products[index].primary_product.selected_qty,
            coupon_id: this.cart_products[index].coupon_id
          };

          if (this.cart_products[index].flash_dealid) {
            itemobj.flash_deal = this.cart_products[index].flash_dealid;
          }

          items.push(itemobj);
        }
      }
      let final_checkout = {
        address_id: this.delivery_address._id,
        billing_address_id: this.bill_address,
        billing_mobile: this.billing_phone,
        shipping_mobile: this.delivery_phone,
        billing_name: this.billing_fullname,
        shipping_name: this.delivery_fullname,
        payment_type: 1,
        items: items,
      };

      if (this.tempAddressData.hasOwnProperty("city")) {
        final_checkout.address_id = this.tempAddressData._id;
        final_checkout.city = this.tempAddressData.city;
      } else {
        this.addressArray.forEach((element) => {
          if (element.is_shipping_address == true) {
            final_checkout.city = element.city._id;
          }
        });
      }

      if (this.user_role == 2) {
        delete final_checkout.address_id;
        delete final_checkout.billing_address_id;

        let countrcode = "+94" + this.guest_form.mobile.replace(/^0+/, "");

        final_checkout.city = this.selectedcityAgent;
        final_checkout.user_email = this.agent_email;
        final_checkout.user_mobile = countrcode.replace(/ /g, "");

        let address_new = this.guest_form.delivery_address;

        if (this.guest_form.billing_address != "") {
          address_new = address_new + ", " + this.guest_form.billing_address;
        }

        final_checkout.robo_shipping_address = address_new;
        final_checkout.robo_billing_address = address_new;

        final_checkout.billing_mobile = countrcode.replace(/ /g, "");
        final_checkout.shipping_mobile = countrcode.replace(/ /g, "");
        final_checkout.billing_name =
          this.guest_form.first_name + " " + this.guest_form.last_name;
        final_checkout.shipping_name =
          this.guest_form.first_name + " " + this.guest_form.last_name;
        final_checkout.order_type = 2;
      }

      if (this.pamentType == 2) {
        final_checkout.payment_type = 2;
      }
      if (this.pamentType == 3) {
        final_checkout.payment_type = 3;
        final_checkout.payment_image = this.image;
      }
      if (this.pamentType == 4) {
        final_checkout.payment_type = 4;
      }
      // Voucher Addition
      if (this.voucherID != "") {
        final_checkout.voucher_id = this.voucherID;
      }
      // console.log("final_checkout",fi+nal_checkout);
      this.postCheckout(final_checkout);
    },
    selectAllItems(type = null) {
      if (type === "both") {
        if (this.selectAllcheckBoth) {
          this.selectAllcheck = true;
          this.selectAllcheckCard = true;
          this.selectAllcheckCash = true;
          for (let index = 0; index < this.cart_products.length; index++) {
            this.cart_products[index].selected = true;
          }
        } else {
          this.selectAllcheck = false;
          this.selectAllcheckCard = false;
          this.selectAllcheckCash = false;
          for (let index = 0; index < this.cart_products.length; index++) {
            this.cart_products[index].selected = false;
          }
        }

        return;
      }

      if (this.selectAllcheck) {
        for (let index = 0; index < this.cart_products.length; index++) {
          this.cart_products[index].selected = true;
        }
      } else {
        for (let index = 0; index < this.cart_products.length; index++) {
          this.cart_products[index].selected = false;
        }
      }

      this.calculateTotalfee();
    },
    singleSelect(status, type) {
      if (!status) {
        if (type === 1) {
          this.selectAllcheckCash = false;

          if (this.cart_products_card.length == 1) {
            this.selectAllcheckCard = true;
          }

          for (let index = 0; index < this.cart_products_cash.length; index++) {
            this.cart_products_cash[index].selected = false;
          }
        }

        if (type === 2) {
          this.selectAllcheckCard = false;

          if (this.cart_products_cash.length == 1) {
            this.selectAllcheckCash = true;
          }
          for (let index = 0; index < this.cart_products_card.length; index++) {
            this.cart_products_card[index].selected = false;
          }
        }
      } else {
        if (type === 1) {
          this.selectAllcheckCard = false;
          this.selectAllcheckCash = false;

          if (this.cart_products_card.length == 1) {
            // this.selectAllcheckCard = true;
          }

          for (let index = 0; index < this.cart_products_cash.length; index++) {
            this.cart_products_cash[index].selected = false;
          }
        }

        if (type === 2) {
          this.selectAllcheckCard = false;
          this.selectAllcheckCash = false;

          if (this.cart_products_cash.length == 1) {
            this.selectAllcheckCash = true;
          }
          for (let index = 0; index < this.cart_products_card.length; index++) {
            this.cart_products_card[index].selected = false;
          }
        }
      }
    },
    selectAllItems_CardCash(type) {
      this.selectAllcheckBoth = false;

      if (type === 1) {
        this.selectAllcheckCard = true;
        this.selectAllcheckCash = false;
      }

      if (type === 2) {
        this.selectAllcheckCard = false;
        this.selectAllcheckCash = true;
      }

      if (this.selectAllcheckCard) {
        this.selectAllcheckCash = false;

        for (let index = 0; index < this.cart_products_card.length; index++) {
          this.cart_products_card[index].selected = true;
        }

        for (let index = 0; index < this.cart_products_cash.length; index++) {
          this.cart_products_cash[index].selected = false;
        }
      }

      if (this.selectAllcheckCash) {
        this.selectAllcheckCard = false;

        for (let index = 0; index < this.cart_products_card.length; index++) {
          this.cart_products_card[index].selected = false;
        }

        for (let index = 0; index < this.cart_products_cash.length; index++) {
          this.cart_products_cash[index].selected = true;
        }
      }

      // if(this.selectAllcheck){
      // 	for (let index = 0; index < this.cart_products.length; index++) {
      // 		this.cart_products[index].selected = true;
      // 	}
      // } else {
      // 	for (let index = 0; index < this.cart_products.length; index++) {
      // 		this.cart_products[index].selected = false;
      // 	}
      // }

      this.calculateTotalfee();
    },
    postCheckout: async function(obj) {
      try {
        this.enableProceed = true;
        let response = "";
        if (this.user_role == 2) {
          response = await this.postAgentCheckoutOrder(obj);
          localStorage.removeItem("agent_guestdata");
        } else {
          response = await this.postCheckoutOrder(obj);
        }
        // check payment type 4 and redirect to mastercard payment gateway  response.data = order unique ID
        if (obj.payment_type == 4) {
          // fire payment gateway by VUE function
          // this.goToPaymentGateway(response.data);

          // Fire payment gateway by external ipg.html page
          this.goToPaymentGatewayPublic(response.data, response.amount);
        }

        // $('#addressModalCenter').modal('hide');
        // this.$router.push('/buyer-orders');
        // this.$swal.fire({
        // 	position: 'center',
        // 	type: 'success',
        // 	title: "Order placed",
        // 	showConfirmButton: false,
        // 	timer: 1500
        // });
        $("#addressModalCenter").modal("hide");
        $("#modalBank").modal("hide");
        let productIDArrayMain = [];
        if (this.buynow) {
          productIDArrayMain = JSON.parse(
            localStorage.getItem("cart_product_ids_checkout")
          );
        } else {
          productIDArrayMain = JSON.parse(
            localStorage.getItem("cart_product_ids")
          );
        }

        let productIDArrayCheckout = this.cart_products;

        let checkoutids = [];
        if (productIDArrayMain) {
          for (let index = 0; index < productIDArrayCheckout.length; index++) {
            if (productIDArrayCheckout[index].selected == true) {
              checkoutids.push(
                productIDArrayCheckout[index].primary_product._id
              );
            }
          }

          var cartRemainingArray = productIDArrayMain.ids.filter(function(
            item
          ) {
            if (item.selected == true) {
              return !checkoutids.includes(item.primary_product._id);
            }
          });

          productIDArrayMain.ids = cartRemainingArray;
        }

        if (!this.buynow) {
          localStorage.setItem(
            "cart_product_ids",
            JSON.stringify(productIDArrayMain)
          );
        }

        localStorage.removeItem("billing_fullname");
        localStorage.removeItem("billing_phone");
        localStorage.removeItem("delivery_fullname");
        localStorage.removeItem("delivery_phone");
        localStorage.removeItem("cart_product_ids_checkout");
        if (obj.payment_type != 4) {
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "ORDER PLACED",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("/buyer-orders");
        }
        this.enableProceed = false;
      } catch (error) {
        this.enableProceed = false;
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    NewAddressChange: async function() {
      try {
        this.delivery_fullname = this.temp.fullname;
        this.delivery_phone = this.temp.phonenumber;

        this.fullname = this.temp.fullname;
        this.phonenumber = this.temp.phonenumber;

        let obj = {};
        let _id = "";

        if (this.changeBillingAddress) {
          for (let index = 0; index < this.addressArray.length; index++) {
            if (this.addressArray[index].is_biling_address === true) {
              _id = this.addressArray[index]._id;
            }
          }
          obj = {
            _id: _id,
            address_line_1: this.selectedAddressLine1,
            address_line_2: this.selectedAddressLine2,
            country: "5d8b9e5dfb195816aaea61b3",
            province: this.selectedprovince,
            region: this.selectedregion,
            city: this.selectedcity,
            primary: false,
            mobile: "+94" + this.temp.phonenumber,
            // postal_code: this.selectedpostalcode,
            is_biling_address: true,
            is_shipping_address: false,
          };
        } else {
          for (let index = 0; index < this.addressArray.length; index++) {
            if (this.addressArray[index].is_shipping_address === true) {
              _id = this.addressArray[index]._id;
            }
          }

          obj = {
            _id: _id,
            address_line_1: this.selectedAddressLine1,
            address_line_2: this.selectedAddressLine2,
            country: "5d8b9e5dfb195816aaea61b3",
            province: this.selectedprovince,
            region: this.selectedregion,
            city: this.selectedcity,
            mobile: "+94" + this.temp.phonenumber,
            // postal_code: this.selectedpostalcode,
            primary: false,
            is_biling_address: false,
            is_shipping_address: true,
          };
        }
        let response = await this.putTempNewCheckoutAddress(obj);

        this.tempAddressData = response;

        this.delivery_address._id = response._id;
        this.delivery_address.address_line_1 = response.address_line_1;
        this.delivery_address.address_line_2 = response.address_line_2;

        for (let index = 0; index < this.cityArray[0].length; index++) {
          const element = this.cityArray[0][index];
          if (this.tempAddressData.city == element._id) {
            this.delivery_address.city = element.city_name;
          }
        }

        for (let index = 0; index < this.provinceArray[0].length; index++) {
          const element = this.provinceArray[0][index];
          if (this.tempAddressData.province == element._id) {
            this.delivery_address.province = element.province_name;
          }
        }

        for (let index = 0; index < this.regionArray[0].length; index++) {
          const element = this.regionArray[0][index];
          if (this.tempAddressData.region == element._id) {
            this.delivery_address.region = element.region_name;
          }
        }

        // await this.productAvailByStore(this.tempAddressData.city);

        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Address Updated",
          showConfirmButton: false,
          timer: 1500,
        });

        // await this.getDeliveryrates();
        this.calculateTotalfee();

        $("#addressModalCenter").modal("hide");
        // await this.loadByOrder();
      } catch (error) {
        throw error;
      }
    },
    productAvailByStore: async function(city = "") {
      try {
        let obj = {
          city: city,
          products: [],
        };

        if (city == "") {
          for (let index = 0; index < this.addressArray.length; index++) {
            const element = this.addressArray[index];
            if (element.is_shipping_address == true) {
              obj.city = element.city._id;
            }
          }
        }

        for (let index = 0; index < this.cart_products.length; index++) {
          const element = this.cart_products[index];
          let prodObj = {
            product_id: element.item_id,
            qty: element.primary_product.selected_qty,
            sku: element.sku,
            merchant_id: element.merchant_id,
          };

          obj.products.push(prodObj);
        }

        let response = await this.postAvailableByStore(obj);

        // this.Deliveryrates = [];
        // response.forEach(element => {
        //   this.Deliveryrates.push(element);
        // });

        for (let index = 0; index < response.length; index++) {
          const element = response[index];
          if (element.product_id == "") {
            this.cart_products.forEach((item) => {
              if (element.old_product_id == item.item_id) {
                item.disable = true;
                item.not_available_zone = true;
                this.selectAllcheck = false;
              }
            });

            this.Deliveryrates.forEach((delement) => {
              if (element.old_product_id == delement.product_id) {
                delement.charge = element.charge;
                delement.code = element.code;
                delement.deliver_charge = element.deliver_charge;
                delement.deliver_discount = element.deliver_discount;
                delement.deliver_partners = element.deliver_partners;
              }
            });
          } else {
            if (element.old_product_id != element.product_id) {
              this.cart_products.forEach((item) => {
                if (item.item_id == element.old_product_id) {
                  item.disable = false;
                  delete item.not_available_zone;
                  this.selectAllcheck = true;

                  item.item_id = element.product_id;

                  item.deliver_charge = element.deliver_charge;
                  item.deliver_discount = element.deliver_discount;

                  if (element.deliver_partners.length > 0) {
                    // if (element.deliver_partners[0].hasOwnProperty("deliver")) {
                    item.delivery_company =
                      element.deliver_partners[0].deliver.company_name;

                    // }
                  }
                  if (element.product_data.store.hasOwnProperty("store_name")) {
                    // item.store_name = element.product_data.store.store_name;
                    item.store_name = element.product_data.store.store_name
                  }

                  item.primary_product.quantity =
                    element.product_data.items[0].quantity;
                }
              });

              this.Deliveryrates.forEach((delement) => {
                if (element.old_product_id == delement.product_id) {
                  delement.product_id = element.product_id;
                  delement.charge = element.charge;
                  delement.code = element.code;
                  delement.deliver_charge = element.deliver_charge;
                  delement.deliver_discount = element.deliver_discount;
                  delement.deliver_partners = element.deliver_partners;
                }
              });
            } else {
              this.cart_products.forEach((item) => {
                if (item.item_id == element.old_product_id) {
                  item.disable = false;
                  delete item.not_available_zone;
                  this.selectAllcheck = true;

                  this.Deliveryrates.forEach((delement) => {
                    if (element.old_product_id == delement.product_id) {
                      delement.product_id = element.product_id;
                      delement.charge = element.charge;
                      delement.code = element.code;
                      delement.deliver_charge = element.deliver_charge;
                      delement.deliver_discount = element.deliver_discount;
                      delement.deliver_partners = element.deliver_partners;
                    }
                  });
                }
              });
            }
          }
        }

        this.getDeliveryrates();
        this.calculateTotalfee();
      } catch (error) {
        console.log(error);
      }
    },
    checkAvailableStock: async function() {
      try {
        let obj = {
          ids: [],
        };

        for (let index = 0; index < this.cart_products.length; index++) {
          obj.ids.push(this.cart_products[index].primary_product._id);
        }

        let response = await this.postCheckAvailableStock(obj);

        for (let index = 0; index < this.cart_products.length; index++) {
          let self = this;
          var stockarray = response.filter(function(item) {
            return item._id == self.cart_products[index].primary_product._id;
          });

          if (stockarray.length > 0) {
            this.cart_products[index].primary_product.quantity =
              stockarray[0].quantity;

            if (this.cart_products[index].primary_product.quantity <= 0) {
              this.cart_products[index].disable = true;
              this.cart_products[index].selected = false;
              this.cart_products[index].primary_product.selected_qty = 0;
            }
          }
        }
      } catch (error) {
        throw error;
      }
    },
    fetchAttributes(available_attributes, selected_attributes) {
      let attritextarr = [];
      for (
        let index = 0;
        index < selected_attributes.attributes.length;
        index++
      ) {
        let attri_id = selected_attributes.attributes[index].attribute_id;
        let attri_value =
          selected_attributes.attributes[index].attribute_value_id;
        var atti_text = available_attributes.reduce(function(
          accumulator,
          item
        ) {
          let text = "";
          if (item._id === attri_id) {
            text += item.attribute_name + ": ";

            var attval = item.attribute_values.reduce(function(
              accumulator2,
              itemval
            ) {
              if (itemval._id === attri_value) {
                attritextarr.push(item.attribute_name + ": " + itemval.value);
                return;
              }
            },
            0);
            return attval;
          }
        },
        0);
      }
      return attritextarr;
    },
  },
};
</script>
<style scoped>
.table {
  border: 0px solid #ddd;
}

#weightDiv {
  margin-top: -20px;
  margin-bottom: 20px;
}
.checkoutcart .proceed-btn {
  background-color: #5779ae;
  color: #ffffff;
  border: none;
}
.checkoutcart .proceed-btn:hover {
  color: #000000;
}
.checkoutcart .proceed-btn:disabled {
  /* background-color: lightgray !important; */
  cursor: auto;
}
.btn {
  border-radius: 0;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.proceed-btn:active {
  background-color: #5779ae;
}
.square-contents {
  width: 150px;
  height: 150px;
  border: 1px solid grey;
  border-radius: 5px;
  margin-right: 10px;
}
.align-contents {
  display: flex;
  align-items: center;
  justify-content: center;
}

textarea {
  height: auto !important;
}
.align-squares {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.squares-text {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}
.align-squarescard {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.square-contents {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.square-contents img {
  display: inline-block;
  width: 40%;
}
/* #addressModalCenter { 
  position: absolute !important;
  height: 90vh;
  top: 0px;
  align-items: center;
}
#addressModalCenter .modal-dialog {
  top: 50%;
  transform: translateY(-50%);
} */
.modal-header {
  min-height: 16.43px;
  padding: 15px;
  border-bottom: 1px solid white !important;
}
.input-tel__label {
  display: none !important;
}
.input-tel.has-value .input-tel__label[data-v-c5bc8062] {
  opacity: 0 !important;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  font-size: 8px;
  display: none !important;
}
@media screen and (max-width: 1280px) {
  #orderInfoTable {
    width: 400px !important;
  }
}
</style>
<style>
.input-tel.has-value .input-tel__label[data-v-c5bc8062] {
  opacity: 0 !important;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  font-size: 8px;
  display: none !important;
}
</style>
